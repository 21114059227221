/* eslint-disable */

import React, { Component, useEffect, useRef, useState } from "react";
import {
  useJsApiLoader,
  GoogleMap,
  Polyline,
  Marker,
  InfoWindow,
  DirectionsRenderer,
} from "@react-google-maps/api";
import { twMerge } from "tailwind-merge";

const MapContainer = (props) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyCOEv9J9J-13PxRqN6bkhIfri2QkOWZdtM",
  });

  const [center, setCenter] = useState({ lat: props.latt, lng: props.lngg });

  const mapRef = useRef();

  const handleMapLoaded = (e) => {
    console.log("map loaded", e);
    const bounds = new google.maps.LatLngBounds();
    bounds.extend({ lat: props.pickupLatt, lng: props.pickupLngg });
    bounds.extend({ lat: props.latt, lng: props.lngg });
    const center = bounds.getCenter();
    setCenter(center);
    e.fitBounds(bounds);
  };
  const className = props.mapContainerClassName || "";

  return isLoaded ? (
    <GoogleMap
      onLoad={handleMapLoaded}
      ref={mapRef}
      center={center}
      zoom={12}
      options={{
        zoomControl: false,
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
      }}
      mapContainerClassName={twMerge(className)}
      mapContainerStyle={{ width: "100%", height: "100%" }}
    >
      <Polyline
        path={[
          { lat: props.latt, lng: props.lngg },
          { lat: props.pickupLatt, lng: props.pickupLngg },
        ]}
      />
      <Marker
        icon={{
          url: "https://i.pinimg.com/originals/11/d8/11/11d811ec58d9ea2df6f7b28dcd176058.png",
          scaledSize: new window.google.maps.Size(50, 50),
        }}
        position={{ lat: props.latt, lng: props.lngg }}
      ></Marker>
      <Marker
        icon={{
          url: "https://cdn1.iconfinder.com/data/icons/logistics-delivery-set-3/512/29-512.png",
          scaledSize: new window.google.maps.Size(50, 50),
        }}
        position={{ lat: props.pickupLatt, lng: props.pickupLngg }}
      ></Marker>
    </GoogleMap>
  ) : (
    <p>loading...</p>
  );
};

export default MapContainer;

// import { Map, Marker, InfoWindow, GoogleApiWrapper } from 'google-maps-react';

// const containerStyle = {
//     top: 0,
//     left: 0,
//     width: '100%',
//     height: '100%'
// }

// const mapOptions = {
//   disableDefaultUI: true
// }

// export const MapContainer = (props) =>  {
//     return (
//         <>
//       <Map

//         google={props.google}
//         zoom={12}
//         style={containerStyle}
//         initialCenter={
//           {
//             lat: props.latt,
//             lng: props.lngg
//           }
//         }
//       >
//           <Marker

//           name="Delivery Location"
//           title="Delivery Location"
//           position={{lat: props.latt, lng: props.lngg}}
//           >
//               <InfoWindow
//               visible
//               >
//                   <div>
//                       <h1>Delivery Location</h1>
//                   </div>
//               </InfoWindow>
//           </Marker>
//           <Marker
//           name="Delivery Location"
//           title="Delivery Location"
//           position={{lat: props.pickupLatt, lng: props.pickupLngg}}
//           />
//       </Map>
//       </>
//     );
//   }

// export default GoogleApiWrapper({
//   apiKey: 'AIzaSyCOEv9J9J-13PxRqN6bkhIfri2QkOWZdtM'
// })(MapContainer);
