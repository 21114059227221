import React from "react";
import PhoneInputComponent, { PhoneInputProps } from "react-phone-input-2";

interface PropTypes extends PhoneInputProps {
  label?: string;
  error?: string;
}

const PhoneInput = ({ label, error, ...rest }: PropTypes) => {
  return (
    <div className="flex flex-col">
      {label && <label className="label">{label}</label>}
      <PhoneInputComponent
        inputClass="input input-bordered"
        containerClass="input input-bordered"
        inputStyle={{
          border: "none",
          height: "100%",
          width: "100%",
        }}
        buttonStyle={{
          background: "transparent",
          border: "none",
        }}
        country="us"
        {...rest}
      />
      {error && (
        <label className="label">
          <span className="label-text-alt text-red-600">{error}</span>
        </label>
      )}
    </div>
  );
};

export default PhoneInput;
