import React, { useEffect, useState } from "react";
import { BsBarChartLine } from "react-icons/bs";
import { CiGlobe, CiSettings } from "react-icons/ci";
import { FaUsers } from "react-icons/fa";
import { IoIosHelpCircleOutline } from "react-icons/io";
import {
  MdOutlineFeedback,
  MdOutlineProductionQuantityLimits,
} from "react-icons/md";
import {
  RiAdminFill,
  RiNotification2Line,
  RiUserShared2Fill,
} from "react-icons/ri";
import { MdDiscount } from "react-icons/md";

import { VscRequestChanges } from "react-icons/vsc";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { logout } from "../../Redux/slices/auth";
import { useAppDispatch } from "../../Redux/store";
import ApiManager, { instance } from "../../utils/ApiManager";

import { CiLogout } from "react-icons/ci";
import SidebarLink from "./SidebarLink";

const Sidebar = () => {
  const [unReviewedDriverCount, setUnreviewedDriverCount] = useState(0);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const getUnReviewedDriverCount = async () => {
    try {
      const res = await instance.get("/admin/driver/unreviewed/count");
      if (res.status === 200) {
        setUnreviewedDriverCount(res.data?.count);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getUnReviewedDriverCount();
  }, []);

  const handleLogout = async () => {
    new ApiManager().logout();
    dispatch(logout());
    navigate("/login");
  };

  return (
    <>
      <div
        className={`${
          location.pathname === "/login" ? "hidden" : ""
        }  font-inter flex pl-10 pr-32 justify-center gap-4 items-center flex-col fixed bg-white top-0 h-screen w-1/4`}
      >
        <img
          className="w-56"
          src="https://static.rock.so/file/4X20DN1p/NjODYRvK/9e3c1ac42b544810a8960ba5329d09c5/t-Swyvery-02.png"
          alt="Logo"
        />
        <div className="w-full mt-4 h-4/5 overflow-y-auto overflow-x-hidden ">
          <SidebarLink
            icon={BsBarChartLine}
            label="Dashboard"
            link="/dashboard"
          />
          <SidebarLink icon={FaUsers} label="Users" link="/dashboard/users" />
          <SidebarLink
            renderRight={
              unReviewedDriverCount > 0 ? (
                <span className="bg-green-100 ml-2 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded">
                  {unReviewedDriverCount}
                </span>
              ) : null
            }
            icon={RiUserShared2Fill}
            label="Drivers"
            link="/dashboard/drivers"
          />
          <SidebarLink
            link="/dashboard/services"
            icon={MdOutlineProductionQuantityLimits}
            label="Services"
          />
          <SidebarLink
            link="/dashboard/feedback"
            icon={MdOutlineFeedback}
            label="Feedback"
          />
          <SidebarLink
            link="/dashboard/submit-requests"
            icon={VscRequestChanges}
            label="Submission Requests"
          />
          <SidebarLink
            link="/dashboard/help-desk"
            icon={IoIosHelpCircleOutline}
            label="Help Desk"
          />
          <SidebarLink
            link="/dashboard/notification"
            label="Notifications"
            icon={RiNotification2Line}
          />
          <SidebarLink icon={CiGlobe} label="States" link="/dashboard/states" />
          <SidebarLink
            icon={RiAdminFill}
            label="Admins"
            link="/dashboard/admins"
          />

          <SidebarLink
            icon={MdDiscount}
            label="Promo Codes"
            link="/dashboard/promo-codes"
          />

          <SidebarLink
            link="/dashboard/accountSettings"
            label="Settings"
            icon={CiSettings}
          />
        </div>
        <button
          className="px-6 flex-shrink-0 flex items-center gap-4 h-11 bg-red-600 rounded text-white mb-4 "
          onClick={handleLogout}
        >
          <CiLogout size={20} color="white" />
          Logout
        </button>
        {/* </div> */}
      </div>
      <Outlet />
    </>
  );
};

export default Sidebar;
