import React, { useEffect, useRef, useState } from "react";
import ApiManager from "../utils/ApiManager";
import empty from "../assets/undraw_no_data_re_kwbl.svg";
import OrdersModal from "./OrdersModal";
import Pagination from "./Pagination";
import dayjs from "dayjs";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai"
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";


const ServicesDashboard = () => {
    const [isViewApplocationVisible, setViewApplicationVisible] = useState(false);
    const [services, setServices] = useState([]);
    const [past, setPast] = useState("")
    const [type, setType] = useState("")
    const [totalPage, setTotalPage] = useState(1)
    const [fromDate, setFromDate] = useState("")
    const [toDate, setToDate] = useState("")
    // const [order, setOrder]  = useState()
    const [loading, setLoading] = useState(false);
    const [totalPages, setTotalPages] = useState(0)
    // const [sort, setSort] = useState("desc")
    // const [past, setPast] = useState("")
    // const [fromDate, setFromDate] = useState()
    // const [toDate, setToDate] = useState()
    const [currentPage_, setCurrentPage_] = useState(0)
    const [hasNextPage, setHasNextPage] = useState(false)
    const [hasPreviousPage, setHasPrevPage] = useState(false)


    // const [persons, setPersons] = useState(orders);
    const [currentPage, setCurrentPage] = useState(1);
    const [personsPerPage, setPersonsPerPage] = useState(10);

    const indexOfLastPerson = currentPage * personsPerPage;
    const indexOfFirstPerson = indexOfLastPerson - personsPerPage;
    // const currentPersons = persons.slice(indexOfFirstPerson, indexOfLastPerson);

    const pageNumbers = [];
    // const totalPersons = persons.length;

    // for (let i = 1; i <= Math.ceil(totalPersons / personsPerPage); i++) {
    // 	pageNumbers.push(i);
    // }

    // const paginate = pageNumber => {
    // 	setCurrentPage(pageNumber);
    // };

    const form = useRef()

    const getAllServices = async () => {
        setLoading(true);
        let res = await new ApiManager().gettransactions();

        if (res?.success) {
            console.log("res", res)
            setServices(res?.payload?.data.transactions)
            setTotalPage(res?.payload?.data?.totalPages)
            //  console.log("services",services)
            //  console.log("total page",totalPage)
        }
        setLoading(false);
    };

    const filterServices = async (e) => {
        e.preventDefault()
        setLoading(true)
        console.log(type)
        const res = await new ApiManager().gettransactions(type)
        if (res?.success) {
            setServices(res?.payload?.data?.transactions)
            setTotalPage(res?.payload?.data?.totalPages)
        }
        else {
            alert(res?.message)
        }
        setLoading(false)
    }

    const handlePageClick = async (e) => {
        setLoading(true)
        const page = e.selected + 1
        const res = await new ApiManager().gettransactions(type, page)
        if (res?.success) {
            setServices(res?.payload?.data?.transactions)
            console.log("asdads",res)
        }
        setLoading(false)
    }

    const resetFilters = () => {
        form.current.reset()
        setPast("")
        setType("")
        getAllServices()
    }
    useEffect(() => {
        getAllServices();
    }, []);
    // useEffect(() => {
    // 	if(fromDate > toDate){
    // 		alert("from date cannot be greater than to date")
    // 	}
    //   }, [toDate,fromDate])

    // const handlePageClick = async (page) => {
    // 	setLoading(true)
    //     // console.log(event)
    //     // let res = await new ApiManager().getOrders(10, "desc" , page)
    // 	if(res.success){
    // 		setOrders(res?.payload.data.docs)
    // 		setCurrentPage_(res.payload.data.page)
    // 		setHasNextPage(res.payload.data.hasNextPage)
    // 		setHasPrevPage(res.payload.data.hasPrevPage)
    // 	}
    // 	console.log(res)
    // 	setLoading(false)
    //   };

    //   const form = useRef()

    //   const filterOrders = async (e) => {
    // 	e.preventDefault()
    // 	setLoading(true)
    // 	const res = await new ApiManager().getOrders(10, "desc",currentPage_, dayjs(fromDate).format("MM-DD-YYYY"), dayjs(toDate).format("MM-DD-YYYY"), null, null, null, past)
    // 	if(res.success){
    // 		setOrders(res.payload.data.docs)
    // 		setTotalPages(res.payload.data.totalPages)
    // 		setCurrentPage_(res.payload.data.page)
    // 		setHasNextPage(res.payload.data.hasNextPage)
    // 		setHasPrevPage(res.payload.data.hasPrevPage)
    // 	}
    // 	setLoading(false)
    //   }


    return (
        <div className='w-full h-full'>
            {/* <Sidebar/> */}
            <Helmet>
                <title>Transaction Dashboard</title>
            </Helmet>

            <div className='w-80% px-6 py-3 relative left-20% flex flex-col justify-center font-inter bg-gray-100 h-screen'>

                {/* <div className='w-full h-20 flex justify-between items-center'> */}
                <p className='text-xl mb-4 font-semibold '>Transactions</p>
                {/* </div> */}
                <div className='w-full h-20 flex justify-between items-center'>
                    <form ref={form} onSubmit={filterServices} className="w-full flex items-center justify-end mb-4">
                        <select
                            onChange={e => setType(e.target.value)}
                            defaultValue=""
                            // onChange={e => setPast(e.target.value)}
                            className='w-32 outline-none bg-white rounded-lg h-10 pl-4 pr-2 ml-4'
                        >
                            <option value="" disabled hidden>Type</option>
                            <option value="credit">Credit</option>
                            <option value="debit">Debit</option>
                            <option value="withdrawal">Withdrawal</option>


                        </select>
                        {/* <select
                            defaultValue=""
                            onChange={e => setPast(e.target.value)}
                            className='w-32 outline-none bg-white rounded-lg h-10 pl-4 pr-2 ml-4'
                        >
                            <option value="" disabled hidden>Time</option>
                            <option value="">All</option>
                            <option value="1">Today</option>
                            <option value="7" >Last Week</option>
                            <option value="30">Last Month</option>
                        </select> */}
                        {/* <select
								defaultValue=""
								onChange={e => setSort(e.target.value)}
								className='w-32 outline-none bg-white rounded-lg h-10 pl-4 pr-2 ml-4 '
							>
								<option value="" disabled hidden>Sort</option>
								<option value="desc">Descending</option>
								<option value="asc">Ascending</option>
							</select> */}
                        {/* <div className='flex items-center ml-4'>
                            <label htmlFor='from_date'>From: </label>
                            <input onChange={e => setFromDate(e.target.value)} id='from_date' className='w-44 rounded h-10 bg-white ml-2 px-2' type='date' />
                        </div>
                        <div className='flex items-center ml-4'>
                            <label htmlFor='to_date'>To: </label>
                            <input onChange={e => setToDate(e.target.value)} id='to_date' className='w-44 rounded h-10 bg-white ml-2 px-2' type='date' />
                        </div> */}
                        <button type="button" onClick={resetFilters} className="w-24 ml-4 h-10 bg-transparent border rounded-lg border-primary text-primary">Clear</button>

                        <button type="submit" className="w-28 ml-4 h-10 bg-primary rounded-lg  text-white">Apply</button>
                        <button type="button" onClick={() => window.location.reload(true)} className="w-32 bg-transparent border border-primary text-primary h-10 ml-4 rounded">Refresh</button>
                    </form>
                </div>

                <div className='h-4/5 px-14 overflow-auto flex flex-col justify-between w-full bg-white rounded-lg'>
                    {loading === false && services?.length === 0 ? (
                        <div className="w-full h-full flex flex-col items-center justify-center">
                            <img className='w-1/5' src={empty} />
                            <p className='mt-10 text-2xl text-gray-500 font-inter'>No Services Found</p>
                        </div>
                    ) : (

                        <table className='w-full h-4/5 overflow-y-auto overflow-x-auto'>
                            {loading && loading === true ? (
                                <div className="w-full h-full flex items-center justify-center">
                                    <div className="loader"></div>
                                </div>
                            ) : (

                                <>
                                    <tr className='w-full 2xl:justify-evenly flex h-24 items-center'>
                                        <td className='w-44 text-gray-400'>Name</td>
                                        <td className='w-36  text-gray-400'>Amount</td>
                                        <td className='w-36  text-gray-400'>Type</td>
                                        <td className='w-36  text-gray-400'>Email</td>
                                    </tr>
                                    {services?.map(data => (
                                        <tr className='w-full 2xl:justify-evenly flex items-center border-b border-gray-200 py-5'>
                                            <td className='w-44 flex flex-shrink-0 items-center h-full'>
                                                {data.type === "withdrawal" ? data.driver.fullName : data.type === "credit" ? data.user.fullName : data.type === "debit" ? data.driver.fullName : null}
                                            </td>
                                           
                                            <td className='w-36 flex flex-shrink-0 items-center h-full'>
                                                {data.type === "credit" ? data.amount : data.type === "debit" ? data.amount : data.type === "withdrawal" ? data.amount : null}
                                            </td>
                                            <td className='w-36 flex flex-shrink-0 text-md  break-all'>
                                                {data.type === "withdrawal" ? data.type : data.type === "credit" ? data.type : data.type === "debit" ? data.type : null}
                                            </td>
                                            <td className='w-36  flex-shrink-0   mr-8  relative group'>
                                                {data.type === "withdrawal" ? data.driver.email : data.type === "credit" ? data.user.email : data.type === "debit" ? data.driver.email : null}
                                                <p className="bg-gray-100 font-semibold group-hover:opacity-100 opacity-0 absolute group-hover:z-20 z-0 top-17 p-4 text-black">{data.type === "withdrawal" ? data.driver.email : data.type === "credit" ? data.user.email : data.type === "debit" ? data.driver.email : null}</p>

                                            </td>
                                        </tr>
                                    ))
                                    }
                                </>
                            )}
                        </table>
                    )}
                </div>
                <div className="mt-6" >
                    <Pagination page={totalPage} handlePageClick={e => handlePageClick(e)} />
                </div>
            </div>
            <OrdersModal
                isVisible={isViewApplocationVisible}
                setVisible={setViewApplicationVisible}
            />
        </div>
    );
};

export default ServicesDashboard;
