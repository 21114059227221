import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import { Helmet } from "react-helmet-async";
import { BsFillBagDashFill } from "react-icons/bs";
import { GiMoneyStack, GiNotebook } from "react-icons/gi";
import { RiMotorbikeFill } from "react-icons/ri";
import ApiManager from "../utils/ApiManager";

type StatsResponseType = {
  brands: number | null;
  drivers: number | null;
  users: number | null;
  activeOrders: number | null;
  cancelledOrders: number | null;
  completedOrders: number | null;
  newDrivers: number | null;
  ordersCount: number | null;
  newUsers: number | null;
};

const Card = ({
  icon,
  label,
  value,
}: {
  icon: React.ReactNode;
  label: string;
  value: number | null;
}) => {
  return (
    value !== null && (
      <div className="px-6 shadow-lg flex items-center rounded-lg h-32 bg-white mr-10">
        <div className="mr-4   flex justify-center">
          <div className="w-14 h-14 bg-blue-100 flex items-center justify-center rounded-full">
            {icon}
          </div>
        </div>
        <div>
          <p className="text-3xl font-semibold">
            <CountUp duration={0.5} end={value} />
          </p>
          <p className="text-gray-500 text-md">{label}</p>
        </div>
      </div>
    )
  );
};

const Dashboard = () => {
  const [stats, setStats] = useState<StatsResponseType>({
    brands: null,
    drivers: null,
    users: null,
    activeOrders: null,
    cancelledOrders: null,
    completedOrders: null,
    newDrivers: null,
    ordersCount: null,
    newUsers: null,
  });
  const [loading, setLoading] = useState(false);
  const [filterLoading, setFilterLoading] = useState(false);
  const [filter, setFilter] = useState("");

  const getStats = async () => {
    setLoading(true);
    let res = await new ApiManager().getDashboardStats();
    let res2 = await new ApiManager().getExtendedStats();
    if (res.payload && res2.payload) {
      setStats({
        brands: res.payload.data.brands,
        drivers: res.payload.data.drivers,
        users: res.payload.data.users,
        activeOrders: res2.payload.data.activeOrders,
        cancelledOrders: res2.payload.data.cancelledOrders,
        completedOrders: res2.payload.data.completedOrders,
        newDrivers: res2.payload.data.newDrivers,
        ordersCount: res2.payload.data.ordersCount,
        newUsers: res2.payload.data.newUsers,
      });
    }

    setLoading(false);
  };

  const filterStats = async () => {
    setFilterLoading(true);
    let res = await new ApiManager().filterExtendedStats(filter);
    if (res.payload) {
      setStats({
        ...stats,
        cancelledOrders: res.payload.data.cancelledOrders,
        completedOrders: res.payload.data.completedOrders,
        newDrivers: res.payload.data.newDrivers,
        ordersCount: res.payload.data.ordersCount,
        newUsers: res.payload.data.newUsers,
      });
    }
    setFilterLoading(false);
  };

  useEffect(() => {
    getStats();
  }, []);

  useEffect(() => {
    filterStats();
  }, [filter]);

  return (
    <div className="w-full h-full">
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <div
        id="modal"
        className="w-80% px-6 relative left-20% flex flex-col pt-20 font-inter bg-gray-100 min-h-screen"
      >
        {loading === true ? (
          <div
            style={{ height: "70vh" }}
            className="w-full flex items-center justify-center"
          >
            <div className="loader"></div>
          </div>
        ) : (
          <>
            <div className="flex w-full justify-center">
              <Card
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 text-blue-500"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                      clipRule="evenodd"
                    />
                  </svg>
                }
                value={stats?.users}
                label="Total Users Registered"
              />

              <Card
                value={stats?.drivers}
                label="Total Drivers Registered"
                icon={
                  <div className="w-14 h-14 bg-purple-100 flex items-center justify-center rounded-full">
                    <RiMotorbikeFill
                      className="text-purple-400"
                      fontSize="1.5rem"
                    />
                  </div>
                }
              />

              <Card
                icon={
                  <div className="w-14 h-14 bg-green-200 flex items-center justify-center rounded-full">
                    <BsFillBagDashFill
                      className="text-green-500"
                      fontSize="1.5rem"
                    />
                  </div>
                }
                label="Active Orders"
                value={stats?.activeOrders}
              />
            </div>
            <div
              className="bg-gray-200 rounded-lg mt-10 pb-10 px-10 py-10"
              // style={{ height: "20rem" }}
            >
              <div className="w-full flex justify-end">
                <div className="flex items-center">
                  <label htmlFor="filter">Filter By Time:</label>
                  <select
                    onChange={(e) => setFilter(e.target.value)}
                    id="filter"
                    className="w-32 h-8 bg-white ml-2"
                  >
                    <option value="all">All</option>
                    <option value="1">Today</option>
                    <option value="7">Last Week</option>
                    <option value="30">Last Month</option>
                  </select>
                </div>
              </div>
              {filterLoading === true ? (
                <div className="w-full flex justify-center mb-20">
                  <div className="loader"></div>
                </div>
              ) : (
                <>
                  <div className="flex mt-10 w-full justify-center">
                    <Card
                      icon={
                        <div className="w-14 h-14 bg-green-100 flex items-center justify-center rounded-full">
                          <GiNotebook
                            className="text-green-400"
                            fontSize="1.5rem"
                          />
                        </div>
                      }
                      label="All Orders"
                      value={stats?.ordersCount}
                    />

                    <Card
                      icon={
                        <div className="w-14 h-14 bg-green-200 flex items-center justify-center rounded-full">
                          <GiMoneyStack
                            className="text-green-500"
                            fontSize="1.5rem"
                          />
                        </div>
                      }
                      label="Completed Orders"
                      value={stats?.completedOrders}
                    />

                    <Card
                      icon={
                        <div className="w-14 h-14 bg-red-100 flex items-center justify-center rounded-full">
                          <BsFillBagDashFill
                            className="text-red-500"
                            fontSize="1.5rem"
                          />
                        </div>
                      }
                      label="Cancelled Orders"
                      value={stats?.cancelledOrders}
                    />
                  </div>
                  <div className="flex items-center justify-center mt-10">
                    <Card
                      icon={
                        <div className="w-14 h-14 bg-purple-100 flex items-center justify-center rounded-full">
                          <RiMotorbikeFill
                            className="text-purple-400"
                            fontSize="1.5rem"
                          />
                        </div>
                      }
                      value={stats?.newDrivers}
                      label="Drivers Registered"
                    />
                    <Card
                      icon={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6 text-blue-500"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                            clipRule="evenodd"
                          />
                        </svg>
                      }
                      value={stats?.newUsers}
                      label="New Users"
                    />
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
