import React from "react";

import DataTable, { TBody, TH, THead, TR } from "./DataTable";
import Spinner from "./Spinner.jsx";
import empty from "../assets/undraw_no_data_re_kwbl.svg";
import Pagination from "./Pagination.js";

interface PropTypes {
  data: any[];
  columns: { header: string; accessor: (item: any) => any }[];
  isFetching: boolean;
  emptyStateMessage: string;
  currentPage: number;
  setCurrentPage: any;
  totalPages: number;
}

const DataListView = ({
  data,
  columns,
  isFetching,
  emptyStateMessage,
  currentPage,
  setCurrentPage,
  totalPages,
}: PropTypes) => {
  return (
    <>
      <div className="h-4/5 overflow-auto flex flex-col justify-between w-full bg-white rounded-lg">
        {isFetching ? (
          <Spinner />
        ) : data?.length === 0 ? (
          <div className="w-full h-full flex flex-col items-center justify-center">
            <img className="w-1/5" src={empty} alt="No data found" />
            <p className="mt-10 text-2xl text-gray-500 font-inter">
              {emptyStateMessage || "No Data Found"}
            </p>
          </div>
        ) : (
          <DataTable>
            <THead>
              <TR>
                {columns.map((column, index) => (
                  <TH key={index}>{column.header}</TH>
                ))}
              </TR>
            </THead>
            <TBody>
              {data?.map((item: any, rowIndex: number) => (
                <TR key={rowIndex}>
                  {columns.map((column: any, colIndex: number) => (
                    <TH key={colIndex}>{column.accessor(item) ?? "N/A"}</TH>
                  ))}
                </TR>
              ))}
            </TBody>
          </DataTable>
        )}
      </div>
      <div className="mt-6">
        <Pagination
          page={totalPages}
          handlePageClick={(e: { selected: number }) =>
            setCurrentPage(e.selected + 1)
          }
        />
      </div>
    </>
  );
};

export default DataListView;
