import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { fetchBaseQueryHelper } from "../../utils/helpers";

const helpDeskApi = createApi({
  reducerPath: "helpDeskApi",
  baseQuery: fetchBaseQuery(fetchBaseQueryHelper),
  tagTypes: ["HelpDesk"],
  endpoints: (builder) => ({
    getAllComplaints: builder.query({
      query: (params) => ({
        url: `/admin/complaint/all`,
        params: { ...params },
      }),
    }),
    getComplaintById: builder.query({
      query: id => ({
        url: `/admin/complaint/${id}`
      })
    })
  }),
});

export const { useGetAllComplaintsQuery, useGetComplaintByIdQuery } = helpDeskApi;

export default helpDeskApi;
