import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useGetComplaintByIdQuery } from "../../api/helpDesk/helpDesk";
import Spinner from "../Spinner.jsx";
import { Link } from "react-router-dom";
import Map from "../Map.js";
import { IoIosInformationCircleOutline } from "react-icons/io";

interface PropTypes {
  isVisible: boolean;
  onHide: () => void;
  id: string;
}

const ViewDetails = ({ isVisible, onHide, id }: PropTypes) => {
  const { data, isFetching: isLoading } = useGetComplaintByIdQuery(id, {
    skip: id === "",
  });

  const reportingMap: { [key: string]: string } = {
    user: "reportingUser",
    driver: "reportingDriver",
  };

  return (
    <Transition.Root show={isVisible} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={onHide}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              onClick={onHide}
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block p-4 border border-primary align-bottom bg-[#EEEFF1] rounded-2xl text-left overflow-hidden shadow-xl transform transition-all lg:max-w-2xl sm:align-middle sm:max-w-lg sm:w-full">
              <div className="inline-block p-4 align-bottom bg-white rounded-2xl text-left overflow-hidden shadow-xl transform transition-all lg:max-w-2xl sm:align-middle sm:max-w-lg sm:w-full">
                <div className="w-full flex justify-end">
                  <AiOutlineClose className="cursor-pointer" onClick={onHide} />
                </div>
                <div className="w-full flex flex-col justify-center gap-4 h-full">
                  {isLoading ? (
                    <Spinner loaderClassName="mt-0" />
                  ) : (
                    data && (
                      <>
                        <p>
                          <strong>Reported By: </strong>
                          {data?.service[data?.userType] ? (
                            <Link
                              to={
                                data?.userType === "user"
                                  ? `/dashboard/users/${data?.service?.user?._id}`
                                  : `/dashboard/drivers/${data?.service?.driver?._id}`
                              }
                            >
                              <span className="text-primary underline">
                                {data[reportingMap[data?.userType]]?.fullName ||
                                  "N/A"}
                              </span>
                            </Link>
                          ) : (
                            <span className="underline text-primary">N/A</span>
                          )}
                        </p>
                        <p>
                          <strong>Title: </strong>
                          {data?.title || "N/A"}
                        </p>
                        <div className="flex items-center justify-between">
                          <p>
                            <strong>Description: </strong>
                          </p>
                          <Link
                            to={`/dashboard/services/${data?.service?.serviceId}`}
                          >
                            <IoIosInformationCircleOutline
                              size={24}
                              className="text-primary"
                            />
                          </Link>
                        </div>
                        <div className="border border-primary rounded-xl p-4">
                          <p>{data?.description || "N/A"}</p>
                        </div>
                        <div className="flex items-center justify-between">
                          <p>
                            <strong>User: </strong>
                            {data?.service?.user ? (
                              <Link
                                to={`/dashboard/users/${data?.service?.user?._id}`}
                              >
                                <span className="text-primary underline">
                                  {data?.service?.user?.fullName}
                                </span>
                              </Link>
                            ) : (
                              <span className="text-primary underline">
                                N/A
                              </span>
                            )}
                          </p>
                          <p>
                            <strong>Driver: </strong>
                            {data?.service?.driver ? (
                              <Link
                                to={`/dashboard/drivers/${data?.service?.driver?._id}`}
                              >
                                <span className="text-primary underline">
                                  {data?.service?.driver?.fullName}
                                </span>
                              </Link>
                            ) : (
                              <span className="text-primary underline">
                                N/A
                              </span>
                            )}
                          </p>
                        </div>

                        <div className="h-56 w-full rounded-xl">
                          <Map
                            mapContainerClassName="rounded-xl"
                            lngg={
                              data?.service?.serviceMetaType ===
                              "instore_pickup_meta"
                                ? data?.instore_pickup_meta[0]?.deliveryLocation
                                    ?.lng
                                : data[data?.service?.serviceMetaType]
                                    ?.deliveryLocation?.lng
                            }
                            latt={
                              data?.service?.serviceMetaType ===
                              "instore_pickup_meta"
                                ? data?.instore_pickup_meta[0]?.deliveryLocation
                                    ?.lat
                                : data[data?.service?.serviceMetaType]
                                    ?.deliveryLocation?.lat
                            }
                            pickupLatt={
                              data?.service?.serviceMetaType ===
                              "instore_pickup_meta"
                                ? data?.instore_pickup_meta[0]?.pickupLocation
                                    ?.lat
                                : data[data?.service?.serviceMetaType]
                                    ?.pickupLocation?.lat
                            }
                            pickupLngg={
                              data?.service?.serviceMetaType ===
                              "instore_pickup_meta"
                                ? data?.instore_pickup_meta[0]?.pickupLocation
                                    ?.lng
                                : data[data?.service?.serviceMetaType]
                                    ?.pickupLocation?.lng
                            }
                          />
                        </div>
                      </>
                    )
                  )}
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ViewDetails;
