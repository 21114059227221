import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { AiOutlineClose } from "react-icons/ai";
import amazing from "../assets/amazing-outline.png";
import average from "../assets/average-outline.png";
import bad from "../assets/bad-outline.png";
import amazingHighlighted from "../assets/feedback-amazing.png";
import averageHighlighted from "../assets/feedback-average.png";
import badHighlighted from "../assets/feedback-bad.png";
import goodHighlighted from "../assets/feedback-good.png";
import terribleHighlighted from "../assets/feedback-terrible.png";
import good from "../assets/good-outline.png";
import terrible from "../assets/terrible-outline.png";
import { formatDate } from "../utils/helpers";
import ExperienceRating from "./ExperienceRating";
import { experienceRatingMap } from "./Feedback";
import Rating from "./Rating";

const emojis = [
  {
    experienceRating: 1,
    inactive: terrible,
    active: terribleHighlighted,
  },
  { experienceRating: 2, inactive: bad, active: badHighlighted },
  {
    experienceRating: 3,
    inactive: average,
    active: averageHighlighted,
  },
  { experienceRating: 4, inactive: good, active: goodHighlighted },
  {
    experienceRating: 5,
    inactive: amazing,
    active: amazingHighlighted,
  },
];

const ViewFeedback = ({ isVisible, setVisible, feedback, screen }) => {
  console.log(feedback, "geed");
  return (
    <Transition.Root show={isVisible} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setVisible}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              onClick={() => setVisible(false)}
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block p-4 align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="w-full flex justify-end">
                <AiOutlineClose
                  className="cursor-pointer"
                  onClick={() => setVisible(false)}
                />
              </div>
              <div className="w-full flex flex-col justify-center gap-4 h-full">
                {screen !== "driverDetails" &&
                  (feedback?.userType === "user" ? (
                    <p>
                      <span className="font-bold">User Name: </span>
                      {feedback?.user}
                    </p>
                  ) : (
                    <p>
                      <span className="font-bold">Driver Name: </span>
                      {feedback?.driver}
                    </p>
                  ))}
                <p>
                  <span className="font-bold">Experience Rating: </span>
                </p>
                <div className="flex flex-col items-center justify-center">
                  <div className="flex items-center gap-4">
                    {screen === "driverDetails" ? (
                      <Rating
                        fontSize="2rem"
                        rating={feedback?.experienceRating}
                      />
                    ) : (
                      <ExperienceRating feedback={feedback} />
                    )}
                  </div>
                  {screen !== "driverDetails" && (
                    <p className="mt-4">
                      {experienceRatingMap[feedback?.experienceRating]}
                    </p>
                  )}
                </div>
                <p>
                  <span className="font-bold">Feedback: </span>
                  {feedback?.feedback ? feedback.feedback : "N/A"}
                </p>
                {screen !== "driverDetails" && (
                  <>
                    <p>
                      <strong>Device: </strong>
                      {feedback?.device}
                    </p>
                    <p>
                      <strong>Build: </strong>
                      {feedback?.build}
                    </p>
                    <p>
                      <strong>Date: </strong>
                      {formatDate(feedback?.createdAt)}
                    </p>
                  </>
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ViewFeedback;
