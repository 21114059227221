import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { logout } from "../Redux/slices/auth";
import ApiManager from "../utils/ApiManager";
import { BASE_URL } from "../utils/config";
import Spinner from "./Spinner";

const AccountSettings = () => {
  const [passwordForm, setPasswordForm] = useState({
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });
  const [loading, setLoading] = useState(false);
  const [passwordError, setPasswordError] = useState("");

  const [versionSupportForm, setVersionSupportForm] = useState({
    latestVersion: "",
    isUpdateRequired: false,
    userType: "user",
    buildNumber: "",
  });
  const [versionSupportFormError, setVersionSupportFormError] = useState("");
  const [versionSupportLoading, setVersionSupportLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChangePassword = async () => {
    if (
      !passwordForm.oldPassword ||
      !passwordForm.newPassword ||
      !passwordForm.confirmNewPassword
    ) {
      setPasswordError("Please enter all required fields!.");
      return;
    }
    if (passwordForm.newPassword !== passwordForm.confirmNewPassword) {
      setPasswordError("Passwords do not match.");
      return;
    }
    setPasswordError("");
    setLoading(true);
    const res = await new ApiManager().changePassword(
      passwordForm.oldPassword,
      passwordForm.newPassword
    );
    if (res.success) {
      new ApiManager().logout();
      dispatch(logout());
      navigate("/login");
      toast(res.payload.data.message, { type: "success" });
    } else {
      toast(res?.error, { type: "error" });
    }
    setLoading(false);
  };

  const handleVersionSupportFormChange = (e) => {
    setVersionSupportForm({
      ...versionSupportForm,
      [e.target.name]: e.target.value,
    });
  };

  const handlePasswordFormChange = (e) => {
    setPasswordForm({ ...passwordForm, [e.target.name]: e.target.value });
  };

  const handleUpdateVersion = async () => {
    if (!versionSupportForm.latestVersion || !versionSupportForm.buildNumber) {
      setVersionSupportFormError("Please enter all the required fields.");
      return;
    }

    const versionPattern = /^0\.0\.\d+$/;
    if (!versionPattern.test(versionSupportForm.latestVersion)) {
      setVersionSupportFormError(
        "Invalid Version Format. The version should be in 0.0.X format."
      );
      return;
    }

    const isValidInteger = /^\d+$/.test(versionSupportForm.buildNumber);

    if (!(isValidInteger && versionSupportForm.buildNumber > 0)) {
      setVersionSupportFormError("Build number must be a positive integer.");
      return;
    }

    setVersionSupportFormError("");
    setVersionSupportLoading(true);

    const res = await new ApiManager().appVersion(versionSupportForm);
    if (res.success) {
      setVersionSupportForm({
        latestVersion: "",
        isUpdateRequired: false,
        userType: "user",
        buildNumber: "",
      });
      toast("App Version Changed Successfully", { type: "success" });
    } else {
      toast(res.error?.error, { type: "error" });
    }
    setVersionSupportLoading(false);
  };

  const getLatestAppVersion = async () => {
    const res = await axios.get(`${BASE_URL}/app/version`);
    console.log(res, "Res");
  };

  useEffect(() => {
    getLatestAppVersion();
  }, []);

  return (
    <div className="w-screen h-screen flex">
      <Helmet>
        <title>Settings</title>
      </Helmet>
      <div className="w-80% px-6 relative left-20% flex flex-col justify-center font-inter bg-gray-100 h-screen">
        <h1 className="text-3xl font-semibold mb-10">Settings</h1>
        <h1 className="text-xl font-semibold mb-6">Change Password</h1>
        <div className="grid grid-cols-3 items-center gap-10 mb-4">
          <div className="flex flex-col">
            <label htmlFor="change_password">Old Password:</label>
            <input
              value={passwordForm.oldPassword}
              name="oldPassword"
              onChange={handlePasswordFormChange}
              className="w-30% h-14 rounded mt-4 px-4 text-sm focus:outline-none"
              placeholder="Enter old password"
            />
          </div>
          <div className="flex flex-col ">
            <label htmlFor="change_password">New Password:</label>
            <input
              value={passwordForm.newPassword}
              name="newPassword"
              onChange={handlePasswordFormChange}
              className="w-30% h-14 rounded mt-4 px-4 text-sm focus:outline-none"
              placeholder="New password"
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="change_password">Confirm New Password:</label>
            <input
              value={passwordForm.confirmNewPassword}
              name="confirmNewPassword"
              onChange={handlePasswordFormChange}
              className="w-30% h-14 rounded mt-4 px-4 text-sm focus:outline-none"
              placeholder="Confirm new password"
            />
          </div>
        </div>
        {passwordError ? (
          <p className="text-red-500 mb-2">{passwordError}</p>
        ) : null}
        <button
          onClick={handleChangePassword}
          disabled={loading}
          className={`${
            loading
              ? "opacity-80 cursor-not-allowed"
              : "opacity-100 cursor-pointer"
          } w-32 flex justify-center items-center h-10 rounded-lg bg-primary text-white`}
        >
          {loading && <Spinner variant="svg" />}
          Save
        </button>
        <h1 className="text-xl font-semibold mt-10 mb-6">Version Support</h1>
        <div className="grid grid-cols-4 gap-10">
          <div className="flex flex-col">
            <label htmlFor="latest_version">Latest Version *:</label>
            <input
              id="latest_version"
              name="latestVersion"
              className="w-30% h-14 rounded mt-4 px-4 text-sm focus:outline-none"
              placeholder="0.0.1"
              value={versionSupportForm.latestVersion}
              onChange={handleVersionSupportFormChange}
            />
          </div>

          <div className="flex flex-col">
            <label htmlFor="build_number">Build Number *:</label>
            <input
              id="build_number"
              type="number"
              min={0}
              name="buildNumber"
              className="w-30% h-14 rounded mt-4 px-4 text-sm focus:outline-none"
              placeholder="Enter Build Number"
              value={versionSupportForm.buildNumber}
              onChange={handleVersionSupportFormChange}
            />
          </div>

          <div className="flex flex-col">
            <label htmlFor="update_required">Update Required *:</label>
            <select
              name="isUpdateRequired"
              onChange={handleVersionSupportFormChange}
              value={versionSupportForm.isUpdateRequired}
              className="h-14 rounded mt-4 px-4"
              id="update_required"
            >
              <option value={false}>No</option>
              <option value={true}>Yes</option>
            </select>
          </div>
          <div className="flex flex-col">
            <label htmlFor="user_type">User Type *:</label>
            <select
              value={versionSupportForm.userType}
              className="h-14 rounded mt-4 px-4"
              id="user_type"
              name="userType"
              onChange={handleVersionSupportFormChange}
            >
              <option value="user">User</option>
              <option value="driver">Driver</option>
            </select>
          </div>
        </div>
        {versionSupportFormError ? (
          <p className="text-red-500 mt-2">{versionSupportFormError}</p>
        ) : null}
        <button
          onClick={handleUpdateVersion}
          disabled={versionSupportLoading}
          className="w-32 flex justify-center mt-4 items-center h-10 rounded-lg bg-primary text-white"
        >
          {versionSupportLoading && <Spinner variant="svg" />}
          Save
        </button>
      </div>
    </div>
  );
};

export default AccountSettings;
