import React, { FormEvent, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import empty from "../assets/undraw_no_data_re_kwbl.svg";
import ConfirmBlockModal from "../components/ConfirmBlockModal.js";
import DataTable, { TBody, TH, THead, TR } from "../components/DataTable";
import EnlargedImage from "../components/Image.js";
import Pagination from "../components/Pagination.js";
import UserDetailsModal from "../components/UserDetailsModal.js";
import { instance } from "../utils/ApiManager";

const NOT_APPROVED = "not approved";
const BLOCKED = "blocked";

export type UserType = {
  createdAt: string;
  email: string;
  fullName: string;
  gender: string;
  isBlocked: boolean;
  isEmailVerified: boolean;
  phone: string;
  profileImage: string;
  updatedAt: string;
  username: string;
  _id: string;
  totalOrders: number;
  completedOrders: number;
};

const DashboardUsers = () => {
  const [users, setUsers] = useState<UserType[]>([]);
  const [loading, setLoading] = useState<boolean | null>(null);
  const [user, setUser] = useState<UserType | null>(null);
  const [search, setSearch] = useState("");
  // const [past, setPast] = useState("");
  const [status, setStatus] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [userBlockId, setUserBlockId] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [filters, setFilters] = useState<{
    search: string;
    approved: string | number;
    blocked: string | number;
    fromDate: string;
    toDate: string;
  }>({
    search: "",
    approved: "",
    blocked: "",
    fromDate: "",
    toDate: "",
  });

  console.log(filters, "filters");

  const statusMap: {
    [key: string]: number;
  } = {
    [NOT_APPROVED]: 0,
    [BLOCKED]: 1,
  };

  const getUsers = async () => {
    setLoading(true);
    let res = await instance.get("/admin/user/all", {
      params: {
        limit: 10,
        page: currentPage,
        sort: "desc",
        search: filters.search,
        approved: filters.approved,
        blocked: filters.blocked,
        searchKey: "fullName",
        // past,
        fromDate: filters.fromDate,
        toDate: filters.toDate,
      },
    });
    if (res.status === 200) {
      setUsers(res.data.docs);
      setTotalPages(res.data.totalPages);
      setCurrentPage(res.data.page);
    }
    setLoading(false);
  };

  const [isViewApplocationVisible, setViewApplicationVisible] = useState(false);
  const [isBlockVisible, setBlockVisible] = useState(false);

  const getSearchedUsers = async (e: FormEvent) => {
    e.preventDefault();
    setFilters((prev) => ({ ...prev, search }));
  };

  useEffect(() => {
    getUsers();
  }, [
    currentPage,
    filters.approved,
    filters.blocked,
    filters.search,
    filters.fromDate && filters.toDate,
  ]);

  const userFilter = async (e: FormEvent) => {
    e.preventDefault();
    setFilters({
      approved: status !== "" ? statusMap[status] : "",
      blocked: status !== "" ? statusMap[status] : "",
      fromDate: fromDate,
      toDate: toDate,
      search: search,
    });
  };

  // const pastRef = useRef<HTMLSelectElement>(null);
  const statusRef = useRef<HTMLSelectElement>(null);
  const form = useRef<HTMLFormElement>(null);

  const resetSelected = () => {
    form.current?.reset();
    setFilters({
      approved: "",
      blocked: "",
      fromDate: "",
      toDate: "",
      search: "",
    });
    // setPast("");
    setStatus("");
    setSearch("");
    setFromDate("");
    setToDate("");
  };

  const handlePageClick = async (page: number) => {
    setCurrentPage(page);
  };

  const handleBlock = async () => {
    if (user?.isBlocked === false) {
      const response = await instance.patch(
        `/admin/user/${userBlockId}/modify`,
        { isBlocked: true }
      );
      if (response.status === 200) {
        setUsers(users.filter((user) => user._id !== userBlockId));
      }
    }
    if (user?.isBlocked === true) {
      const response = await instance.patch(
        `/admin/user/${userBlockId}/modify`,
        { isBlocked: false }
      );
      if (response.status === 200) {
        setUsers(users.filter((user) => user._id !== userBlockId));
      }
    }
  };

  const [zoomed, setZoomed] = useState(false);
  const [image, setImage] = useState("");

  return (
    <div className="w-full h-full">
      <Helmet>
        <title>Users Dashboard</title>
      </Helmet>
      <div className="w-80% z-10 px-6 relative left-20% flex flex-col justify-center font-inter bg-gray-100 h-screen">
        <div
          className={`absolute top-0 ${
            isViewApplocationVisible === true ? "" : "hidden"
          } left-0 w-full h-full bg-black z-20 opacity-20`}
        ></div>
        <div className="w-full h-20 flex justify-between items-center">
          <p className="text-xl font-semibold ">Users</p>
          <form ref={form} onSubmit={(e) => getSearchedUsers(e)}>
            <input
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className="w-52 ml-4 px-4 h-10 outline-none rounded "
              placeholder="search"
            />
            <button
              type="submit"
              className="w-32 h-10 ml-4 rounded bg-primary text-white"
            >
              Search
            </button>
            <Link to="/dashboard/users/addUser">
              <button className="w-32 h-10 ml-4 rounded bg-transparent border-primary border text-primary">
                Add User
              </button>
            </Link>
          </form>
        </div>
        <form
          ref={form}
          onSubmit={(e) => userFilter(e)}
          className="w-full flex items-center justify-end mb-4"
        >
          <div className="flex items-center ml-4">
            <label htmlFor="from_date">From: </label>
            <input
              onChange={(e) => setFromDate(e.target.value)}
              id="from_date"
              className="w-32 rounded h-10 bg-white ml-2 px-2"
              type="date"
            />
          </div>
          <div className="flex items-center ml-4">
            <label htmlFor="to_date">To: </label>
            <input
              onChange={(e) => setToDate(e.target.value)}
              id="to_date"
              className="w-32 rounded h-10 bg-white ml-2 px-2"
              type="date"
            />
          </div>
          {/* <select
            ref={pastRef}
            defaultValue="time"
            onChange={(e) => setPast(e.target.value)}
            className="w-32 outline-none bg-white rounded-lg h-10 pl-4 pr-2 ml-4"
          >
            <option value="time" disabled hidden>
              Time
            </option>
            <option value="">All</option>
            <option value="1">Today</option>
            <option value="7">Last Week</option>
            <option value="30">Last Month</option>
          </select> */}
          <select
            ref={statusRef}
            defaultValue=""
            onChange={(e) => setStatus(e.target.value)}
            className="w-32 outline-none bg-white rounded-lg h-10 pl-4 pr-2 ml-4 "
          >
            <option value="" disabled hidden>
              Status
            </option>
            <option value={NOT_APPROVED}>Not Verified</option>
            <option value={BLOCKED}>Blocked</option>
          </select>
          <button
            onClick={resetSelected}
            className="w-24 ml-4 h-10 bg-transparent border rounded-lg border-primary text-primary"
          >
            Clear
          </button>
          <button
            type="submit"
            className="w-28 ml-4 h-10 bg-primary rounded-lg  text-white"
          >
            Apply
          </button>
          <button
            type="button"
            onClick={() => window.location.reload()}
            className="w-32 bg-transparent border border-primary text-primary h-10 ml-4 rounded"
          >
            Refresh
          </button>
        </form>
        <div className="h-4/5 overflow-x-auto flex flex-col justify-between w-full bg-white rounded-lg">
          {loading === true ? (
            <div className="w-full h-full flex items-center justify-center">
              <div className="loader"></div>
            </div>
          ) : (
            <>
              {users && users.length === 0 ? (
                <div className="w-full h-full flex flex-col items-center justify-center">
                  <img className="w-1/5" src={empty} />
                  <p className="mt-10 text-2xl text-gray-500 font-inter">
                    No Users Found
                  </p>
                </div>
              ) : (
                <>
                  <DataTable>
                    <THead>
                      <TR>
                        <TH>Username</TH>
                        <TH>Email</TH>
                        <TH>Phone</TH>
                        <TH>Total Orders</TH>
                        <TH>Completed Orders</TH>
                        <TH></TH>
                        <TH>Action</TH>
                      </TR>
                    </THead>
                    <TBody>
                      {users?.map((user) => (
                        <TR key={user._id}>
                          <TH className="flex items-center cursor-pointer">
                            <div className="flex-shrink-0 w-8 h-8 border border-black rounded-full">
                              <img
                                onClick={() => {
                                  setImage(user?.profileImage);
                                  setZoomed(true);
                                }}
                                onError={(e) => {
                                  const target = e.target as HTMLImageElement;
                                  target.src =
                                    "https://www.pngfind.com/pngs/m/610-6104451_image-placeholder-png-user-profile-placeholder-image-png.png";
                                }}
                                className="w-full h-full rounded-full object-cover"
                                src={user.profileImage}
                              />
                            </div>
                            <div className="w-full relative group">
                              <p
                                onClick={() => {
                                  setUser(user);
                                  setViewApplicationVisible(true);
                                }}
                                className="ml-2 truncate text-md text-black font-normal"
                              >
                                {user.fullName}
                              </p>
                              <p className="bg-gray-100 font-semibold group-hover:opacity-100 opacity-0 absolute group-hover:z-20 z-0 top-0 p-4 text-black">
                                {user.fullName}
                              </p>
                            </div>
                          </TH>
                          <TH className="relative group">
                            <p className="w-56 2xl:w-72 flex text-black font-normal flex-shrink-0 truncate mr-2 text-md  ">
                              {user.email}
                            </p>
                            <p className="bg-gray-100 font-semibold group-hover:opacity-100 opacity-0 absolute group-hover:z-20 z-0 top-5 p-4 text-black">
                              {user.email}
                            </p>
                          </TH>
                          <TH className="text-black font-normal">
                            {user.phone}
                          </TH>
                          <TH className="text-black font-normal">
                            {user.totalOrders}
                          </TH>
                          <TH className="text-black font-normal">
                            {user.completedOrders}
                          </TH>

                          <TH className="font-semibold text-primary cursor-pointer">
                            <Link to={`/dashboard/users/${user._id}`}>
                              View Details
                            </Link>
                          </TH>
                          <TH
                            onClick={() => {
                              setUserBlockId(user._id);
                              setUser(user);
                              setBlockVisible(true);
                            }}
                            className="cursor-pointer text-red-500 font-semibold"
                          >
                            {user?.isBlocked === false ? "Block" : "UnBlock"}
                          </TH>
                        </TR>
                      ))}
                    </TBody>
                  </DataTable>
                </>
              )}
            </>
          )}
        </div>
        <div className="flex mt-4 mb-8 w-full justify-center items-center">
          <Pagination
            page={totalPages}
            handlePageClick={(e: { selected: number }) =>
              handlePageClick(e.selected + 1)
            }
          />
        </div>
      </div>
      )
      <UserDetailsModal
        user={user}
        isVisible={isViewApplocationVisible}
        setVisible={setViewApplicationVisible}
      />
      <ConfirmBlockModal
        name="user"
        blocked={user?.isBlocked}
        blockId={userBlockId}
        handleBlock={handleBlock}
        isVisible={isBlockVisible}
        setVisible={setBlockVisible}
      />
      <EnlargedImage isVisible={zoomed} setVisible={setZoomed} src={image} />
    </div>
  );
};

export default DashboardUsers;
