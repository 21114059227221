import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import ApiManager from "../utils/ApiManager";
import { isFileSizeValid } from "../utils/helpers";
import BackButton from "./BackButton";
import Button from "./Button";
import Input from "./Input";
import PhoneInput from "./PhoneInput";
import Select from "./Select";

const AddDriver = () => {
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [vehicleMakes, setVehicleMakes] = useState([]);
  const [vehicleModels, setVehicleModels] = useState([]);

  const form = useRef();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      phone: "",
      vehicleNumber: "",
      vehicleMakeYear: "",
      vehicleType: "",
      vehicleMake: "",
      vehicleModel: "",
      vehicleRegistrationFile: "",
      drivingLicenseFile: "",
      vehicleInsuranceFile: "",
      profileImage: "",
      zipCode: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .trim()
        .matches(/^[A-Za-z\s]+$/, "Name must contain only letters")
        .required("This field is required"),
      email: Yup.string().trim().email().required("This field is required"),
      password: Yup.string()
        .trim()
        .min(6, "Password must be at least 6 characters")
        .required("This field is required"),
      phone: Yup.string().trim().required("This field is required"),
      vehicleNumber: Yup.string().trim().required("This field is required"),
      vehicleMakeYear: Yup.number()
        .positive()
        .required("This field is required"),
      vehicleType: Yup.string().trim().required("This field is required"),
      vehicleMake: Yup.string().trim().required("This field is required"),
      vehicleModel: Yup.string().trim().required("This field is required"),
      vehicleRegistrationFile: Yup.mixed()
        .required("This field is required")
        .test("size", "File size must be 1MB or less", (value) => {
          if (value) {
            return isFileSizeValid(value);
          }
        }),
      drivingLicenseFile: Yup.mixed()
        .required("This field is required")
        .test("size", "File size must be 1MB or less", (value) => {
          if (value) {
            return isFileSizeValid(value);
          }
        }),
      vehicleInsuranceFile: Yup.mixed()

        .required("This field is required")
        .test("size", "File size must be 1MB or less", (value) => {
          if (value) {
            return isFileSizeValid(value);
          }
        }),
      profileImage: Yup.mixed()
        .required("This field is required")
        .test("size", "File size must be 1MB or less", (value) => {
          if (value) {
            return isFileSizeValid(value);
          }
        }),
      zipCode: Yup.number().positive().required("This field is required"),
    }),
    onSubmit: async (values) => {
      let res = await new ApiManager().createDriver(
        values.name,
        values.email,
        values.password,
        values.phone,
        values.vehicleNumber,
        values.profileImage,
        values.vehicleRegistrationFile,
        values.drivingLicenseFile,
        values.vehicleInsuranceFile,
        values.zipCode,
        values.vehicleType,
        values.vehicleMake,
        values.vehicleModel,
        values.vehicleMakeYear
      );
      if (res?.success) {
        toast("Driver created successfully", {
          type: "success",
        });
        form.current.reset();
        navigate("/dashboard/drivers");
      } else {
        toast(res?.err?.error, {
          type: "error",
        });
      }
    },
  });

  const handleGetVehicleDetails = async () => {
    const res = await new ApiManager().getVehicleDetails();
    if (res.success) {
      setVehicleTypes(res.data?.types);
    }
  };

  const handleGetVehicleMakes = async () => {
    const res = await new ApiManager().getVehicleDetails({
      type: formik.values.vehicleType,
    });
    if (res.success) {
      setVehicleMakes(res?.data?.makes);
    }
  };

  const handleGetVehicleModels = async () => {
    const res = await new ApiManager().getVehicleDetails({
      type: formik.values.vehicleType,
      make: formik.values.vehicleMake,
    });
    if (res.success) {
      setVehicleModels(res?.data?.models);
    }
  };

  useEffect(() => {
    handleGetVehicleDetails();
  }, []);

  useEffect(() => {
    if (formik.values.vehicleType) {
      handleGetVehicleMakes();
    }
  }, [formik.values.vehicleType]);

  useEffect(() => {
    if (formik.values.vehicleMake) {
      handleGetVehicleModels();
    }
  }, [formik.values.vehicleMake]);

  return (
    <div className="w-80% flex-col relative bg-gray-100 left-20% py-10 min-h-screen flex justify-center px-10">
      <BackButton link="/dashboard/drivers" />
      <h1 className="text-3xl font-semibold mb-4">Create New Driver</h1>
      <form
        ref={form}
        onSubmit={formik.handleSubmit}
        className="w-full grid grid-cols-2 gap-5 py-10 bg-white rounded px-8 justify-center"
      >
        <Input
          label="Full Name: "
          name="name"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          isInvalid={Boolean(formik.touched.name && formik.errors.name)}
          error={formik.errors.name}
          placeholder="Enter Driver's Name"
        />
        <Input
          isInvalid={Boolean(formik.touched.email && formik.errors.email)}
          label="Email: "
          name="email"
          type="email"
          autoComplete="off"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.email}
          placeholder="Enter Driver's Email"
        />
        <Input
          label="Password: "
          name="password"
          type="password"
          isInvalid={Boolean(formik.touched.password && formik.errors.password)}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.password}
          placeholder="Enter Driver's Password"
          autoComplete="new-password"
        />
        <PhoneInput
          label="Phone:"
          onChange={(e) => formik.setFieldValue("phone", e)}
          placeholder="Enter Driver's Phone"
          onBlur={formik.handleBlur}
          error={formik.errors.phone}
        />

        <Input
          label="Vehicle Number: "
          name="vehicleNumber"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.vehicleNumber}
          placeholder="Enter Vehicle Number"
          isInvalid={Boolean(
            formik.touched.vehicleNumber && formik.errors.vehicleNumber
          )}
        />
        <Input
          label="Vehicle Make Year: "
          name="vehicleMakeYear"
          onChange={formik.handleChange}
          type="number"
          onBlur={formik.handleBlur}
          error={formik.errors.vehicleMakeYear}
          placeholder="Enter Vehicle Make Year"
          isInvalid={Boolean(
            formik.touched.vehicleMakeYear && formik.errors.vehicleMakeYear
          )}
        />
        <Select
          onChange={formik.handleChange}
          name="vehicleType"
          label="Vehicle Type: "
          options={vehicleTypes}
          error={formik.errors.vehicleType}
          isInvalid={Boolean(
            formik.touched.vehicleType && formik.errors.vehicleType
          )}
          placeholder="Choose Vehicle Type"
          disabled={vehicleTypes.length === 0}
        />
        <Select
          onChange={formik.handleChange}
          name="vehicleMake"
          label="Vehicle Make: "
          options={vehicleMakes}
          isInvalid={Boolean(
            formik.touched.vehicleMake && formik.errors.vehicleMake
          )}
          error={formik.errors.vehicleMake}
          placeholder="Choose Vehicle Make"
          disabled={vehicleMakes.length === 0}
        />
        <Select
          onChange={formik.handleChange}
          name="vehicleModel"
          label="Vehicle Model: "
          options={vehicleModels}
          error={formik.errors.vehicleModel}
          isInvalid={Boolean(
            formik.touched.vehicleModel && formik.errors.vehicleModel
          )}
          placeholder="Choose Vehicle Model"
          disabled={vehicleModels.length === 0}
        />

        <Input
          label="Vehicle Registration File: "
          className="file-input file-input-ghost"
          error={formik.errors.vehicleRegistrationFile}
          onChange={(e) =>
            formik.setFieldValue("vehicleRegistrationFile", e.target.files[0])
          }
          isInvalid={Boolean(
            formik.touched.vehicleRegistrationFile &&
              formik.errors.vehicleRegistrationFile
          )}
          type="file"
        />
        <Input
          label="Driving License File: "
          className="file-input file-input-ghost"
          error={formik.errors.drivingLicenseFile}
          onChange={(e) =>
            formik.setFieldValue("drivingLicenseFile", e.target.files[0])
          }
          type="file"
          isInvalid={Boolean(
            formik.touched.drivingLicenseFile &&
              formik.errors.drivingLicenseFile
          )}
        />
        <Input
          label="Vehicle Insurance File: "
          className="file-input file-input-ghost"
          error={formik.errors.vehicleInsuranceFile}
          onChange={(e) =>
            formik.setFieldValue("vehicleInsuranceFile", e.target.files[0])
          }
          isInvalid={Boolean(
            formik.touched.vehicleInsuranceFile &&
              formik.errors.vehicleInsuranceFile
          )}
          type="file"
        />
        <Input
          label="Profile Image: "
          className="file-input file-input-ghost"
          error={formik.errors.profileImage}
          onChange={(e) =>
            formik.setFieldValue("profileImage", e.target.files[0])
          }
          isInvalid={Boolean(
            formik.touched.profileImage && formik.errors.profileImage
          )}
          type="file"
        />

        <Input
          label="Zip Code: "
          name="zipCode"
          onChange={formik.handleChange}
          type="number"
          onBlur={formik.handleBlur}
          error={formik.errors.zipCode}
          placeholder="Enter ZipCode: "
          isInvalid={Boolean(formik.touched.zipCode && formik.errors.zipCode)}
        />

        <Button
          isLoading={formik.isSubmitting}
          type="submit"
          className="w-32 bg-primary text-white"
        >
          Create
        </Button>
      </form>
    </div>
  );
};

export default AddDriver;
