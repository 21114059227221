import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ApiManager from '../utils/ApiManager'
import {FaStar} from "react-icons/fa"
import Table from './Table'
import OrderModal from './OrderModal'

const BrandsDetails = () => {
    const {id} = useParams()
    const [details, setDetails] = useState({})
    const [orders, setOrders] = useState([])
    const [loading, setLoading] = useState(false)
    const [orderLoading, setOrderLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [currentPage, setCurrentPage] = useState(0)

    const getUserDetails = async () => {
        setLoading(true)
        let res = await new ApiManager().getBrandDetails(id)
        if(res.success){
            setDetails(res.payload.data)
        }
        setLoading(false)
        // console.log(details)
    }
    const getOrders = async () => {
        setOrderLoading(true)
        let res = await new ApiManager().getBrandOrders(id, "", page)
        if(res.success){
            setOrders(res?.payload?.docs)
            setPage(res?.payload?.totalPages)
            setCurrentPage(res?.payload.page)
        }
        setOrderLoading(false)
    }
    
    useEffect(() => {
        getUserDetails()
        getOrders()
        // console.log(id)

    }, [])
    
    const [isVisible, setVisible] = useState(false)

    const [order,setOrder] = useState()

    useEffect(() => {
        console.log(details)
    }, [details])

    const handlePageClick = async (event) => {
		setLoading(true)
        console.log(event)
        let res = await new ApiManager().getBrandOrders(id, "", event?.selected + 1, )
		if(res.success){
			setOrders(res?.payload.docs)
		}
		console.log(res)
		setLoading(false)
      };
    
    
    return (
        <div className='w-80% px-6 py-10 relative left-20% font-inter flex flex-col justify-center font-inter bg-gray-100 min-h-screen'>
            <div className="flex items-center mb-4">
                <h1 className='text-3xl mr-4'>Brand Details</h1>
                <span className='w-4/5 h-1 bg-gray-300'></span>
            </div>
            <div className='w-full h-72 bg-white rounded-lg shadow-xl flex'>
                {loading === true ? (
                    <div className="w-full h-full flex items-center justify-center">
                        <div className="loader"></div>
                    </div>
                ) : (
                <>
                <div className='w-2/5 h-full flex flex-col items-center justify-center border-r border-gray-300'>
                    <img  onError={e => e.target.src =" https://www.pngfind.com/pngs/m/610-6104451_image-placeholder-png-user-profile-placeholder-image-png.png"} className='w-24 h-24 object-cover rounded-full' src={details.brandLogo} alt='image could not be loaded'/>
                    <h2 className='text-xl mt-4 font-semibold'>{details.brandName}</h2>    
                    {/* {details?.ratingByDriver?.ratingCount != 0 && (
                        <div className='flex items-center mt-4'>
                        <p className='mr-2'>Rating By Driver:</p>
                        {[...Array(5)].map((start,i) => {
                                            const ratingValue = i + 1
                                            return (                                
                                                // <label style={{cursor: "pointer"}} onClick={() => setRatingBehaviour(ratingValue)}>
                                                    // <input value={ratingValue} style={{display: "none"}} type="radio" name="rating" />
                                                    
                                                    <FaStar color={ratingValue <= details?.ratingByDriver?.rating ? "#FDCC0D": "gray"} size={30}/>
                                                    
                                                    
                                                // </label>
                                            )
                        })}
                        <p className='ml-4 text-gray-500'>{details?.ratingByDriver?.ratingCount} rates</p>
                        </div>
                    )}
                    {details?.ratingByUser?.ratingCount != 0 && (
                        <div className='flex items-center mt-4'>
                            <p className='mr-2'>Rating By User:</p>
                            {[...Array(5)].map((start,i) => {
                                                const ratingValue = i + 1
                                                return (                                
                                                    // <label style={{cursor: "pointer"}} onClick={() => setRatingBehaviour(ratingValue)}>
                                                        // <input value={ratingValue} style={{display: "none"}} type="radio" name="rating" />
                                                        
                                                        <FaStar color={ratingValue <= details?.ratingByUser?.rating ? "#FDCC0D": "gray"} size={30}/>
                                                        
                                                        
                                                    // </label>
                                                )
                            })}
                            <p className='ml-4 text-gray-500'>{details?.ratingByUser?.ratingCount} rates</p>
                        </div>
                    )} */}
                </div>

                <div className='w-3/5 h-full flex flex-col px-10 py-10'>
                    <p className='text-2xl mb-4'>Brand Profile</p>
                    <div className='flex justify-center flex-col'>
                        <div className='flex items-center mb-2'>
                            <p className='mr-2 text-gray-500'>Email:</p>
                            <p className='font-semibold'>{details.email}</p>
                        </div>
                        <div className='flex items-center mb-2'>
                            <p className='mr-2 text-gray-500'>Joined:</p>
                            <p className='font-semibold'>{details?.createdAt?.slice(0,10)}</p>
                        </div>
                        <div className='flex items-center mb-2'>
                            <p className='mr-2 text-gray-500'>Contact Number:</p>
                            <p className='font-semibold'>{details.contact}</p>
                        </div>
                    </div>
                </div>
                </>
                )}
            </div>
            <Table currentPage={currentPage} handlePageClick={handlePageClick} setLoading={setOrderLoading} userLoading={loading} loading={orderLoading} setOrder={setOrder} setVisible={setVisible} setOrders={setOrders} getOrders={getOrders} page={page} setPage={setPage} orders={orders} id={details._id}/>
            {/* <div className='w-20 h-20 mb-4 rounded-full flex items-center justify-center'>
                <img onError={e => e.target.src =" https://media.istockphoto.com/photos/put-more-in-get-more-out-picture-id1291318636?b=1&k=20&m=1291318636&s=170667a&w=0&h=UvVIk7wwkN3X9OFm8gBlWWviV5vAjfrq2ejYP30JmnA="} className='w-full h-full object-cover rounded-full' src={details.profileImage} alt='image could not be loaded'/>
            </div>
            <div className='flex items-center mb-2'>
                <p className='text-lg font-semibold mr-2'>Full Name:</p>
                <p className='text-lg'>{details.fullName}</p>
            </div>
            <div className='flex items-center mb-2'>
                <p className='text-lg font-semibold mr-2'>User Name:</p>
                <p className='text-lg'>{details.username}</p>
            </div>
            <div className='flex items-center mb-2'>
                <p className='text-lg font-semibold mr-2'>Email:</p>
                <p className='text-lg'>{details.email}</p>
            </div>
            <div className='flex items-center mb-2'>
                <p className='text-lg font-semibold mr-2'>Phone:</p>
                <p className='text-lg'>{details.phone}</p>
            </div>
            <div className='flex items-center mb-2'>
                <p className='text-lg font-semibold mr-2'>Gender:</p>
                <p className='text-lg'>{details.gender}</p>
            </div>
            <div className='flex items-center mb-2'>
                <p className='text-lg font-semibold mr-2'>Rating:</p>
                <p className='text-lg'>{details.rating}</p>
            </div>
            <div className='flex items-center mb-2'>
                <p className='text-lg font-semibold mr-2'>Created At:</p>
                <p className='text-lg'>{details?.createdAt?.slice(0,10)}</p>
            </div> */}
            <OrderModal order={order} isVisible={isVisible} setVisible={setVisible}/>
        </div>
    )
}

export default BrandsDetails
