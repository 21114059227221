import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../utils/config";
import { prepareHeaders } from "../../utils/helpers";

const servicesApi = createApi({
  reducerPath: "servicesApi",
  tagTypes: ["Services"],
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: prepareHeaders,
  }),
  endpoints: (builder) => ({
    getServices: builder.query({
      query: (params) => ({
        url: "/admin/services/all",
        params,
      }),
      providesTags: ["Services"],
    }),
    cancelService: builder.mutation({
      query: (id) => ({
        url: `/admin/services/${id}/cancel`,
        method: "PATCH",
      }),
      invalidatesTags: ["Services"],
    }),
  }),
});

export const { useGetServicesQuery, useCancelServiceMutation } = servicesApi;

export default servicesApi;
