import React from "react";
import amazing from "../assets/amazing-outline.png";
import average from "../assets/average-outline.png";
import bad from "../assets/bad-outline.png";
import amazingHighlighted from "../assets/feedback-amazing.png";
import averageHighlighted from "../assets/feedback-average.png";
import badHighlighted from "../assets/feedback-bad.png";
import goodHighlighted from "../assets/feedback-good.png";
import terribleHighlighted from "../assets/feedback-terrible.png";
import good from "../assets/good-outline.png";
import terrible from "../assets/terrible-outline.png";

interface PropTypes {
  feedback: {
    experienceRating: number;
  };
}

const emojis = [
  {
    experienceRating: 1,
    inactive: terrible,
    active: terribleHighlighted,
  },
  { experienceRating: 2, inactive: bad, active: badHighlighted },
  {
    experienceRating: 3,
    inactive: average,
    active: averageHighlighted,
  },
  { experienceRating: 4, inactive: good, active: goodHighlighted },
  {
    experienceRating: 5,
    inactive: amazing,
    active: amazingHighlighted,
  },
];
const ExperienceRating = ({ feedback }: PropTypes) => {
  return emojis.map((emoji, index) => {
    const active = feedback?.experienceRating === emoji.experienceRating;
    return (
      <img
        key={emoji.experienceRating + index}
        className="w-12 h-12 object-contain"
        src={active ? emoji.active : emoji.inactive}
      />
    );
  });
};

export default ExperienceRating;
