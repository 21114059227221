import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { fetchBaseQueryHelper } from "../../utils/helpers";

const adminApi = createApi({
  reducerPath: "adminApi",
  baseQuery: fetchBaseQuery(fetchBaseQueryHelper),
  tagTypes: ["Admin"],
  endpoints: (builder) => ({
    getAllAdmins: builder.query({
      query: (params) => ({
        url: "/admin/admins/all",
        params: { ...params },
      }),
      providesTags: ["Admin"],
    }),
    createAdmin: builder.mutation({
      query: (body) => ({
        url: "/auth/admin/register",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Admin"],
    }),
    deleteAdmin: builder.mutation({
      query: (id) => ({
        url: `/admin/admin/${id}/delete`,
        method: "DELETE",
      }),
      invalidatesTags: ["Admin"],
    }),
  }),
});

export const {
  useGetAllAdminsQuery,
  useCreateAdminMutation,
  useDeleteAdminMutation,
} = adminApi;

export default adminApi;
