import React from "react";
import Rating from "react-rating";
import { AiOutlineStar, AiFillStar } from "react-icons/ai";

const RatingComponent = ({ rating, ratingcount, fontSize }) => {
  return (
    <div className="flex items-center">
      <Rating
        fullSymbol={
          <AiFillStar
            color="gold"
            fontSize={fontSize ? fontSize : "1.5rem"}
            className=""
          />
        }
        emptySymbol={
          <AiOutlineStar
            fontSize={fontSize ? fontSize : "1.5rem"}
            className=""
          />
        }
        initialRating={rating}
        readonly
      />
      <p className="ml-2 mb-2">{rating?.toFixed(1)}/5 </p>
      {ratingcount ? <p className="ml-4">({ratingcount} ratings)</p> : null}
    </div>
  );
};

export default RatingComponent;
