import React, { useEffect, useState } from 'react'
import ApiManager from '../utils/ApiManager'

const AddBrand = () => {
    const [name, setName] = useState('')
    const [email,setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [zip, setZip] = useState('')
    const [logo, setLogo] = useState('')
    const [contact, setContact] = useState('')
    const [address, setAddress] = useState("")
    const [cords, setCords] = useState("")

    const handleSubmit = async (e) => {
        e.preventDefault()
        let res = await new ApiManager().createBrand(name, email, password, contact,zip,logo, address, cords)        
        if(res.success){
            alert("Brand Created Successfully")
        }
    }

    useEffect(() => {
        console.log(name,email,password,logo,contact,zip)
    }, [name,email,password,logo,contact,zip])

    return (
        <div className='w-80% flex-col relative bg-gray-100 left-20% h-screen flex justify-center px-10'>
            <h1 className='text-3xl font-semibold mb-4'>Create New Brand</h1>
            <form onSubmit={e => handleSubmit(e)} className='w-full h-4/6 bg-white rounded px-8 flex flex-col justify-center'>
                <div className='flex items-center w-full'>
                    <div className='flex flex-col mb-6 mr-10'>
                        <label className='mr-4 text-lg' htmlFor='brandName'>Name:</label>
                        <input required onChange={e => setName(e.target.value)} id='brandName' className='w-96 text-sm h-10 outline-none border-b border-gray-300' placeholder='Enter Brand Name'/>
                    </div>
                    <div className='flex flex-col mb-6'>
                        <label className='mr-4 text-lg' htmlFor='brandName'>Email:</label>
                        <input required onChange={e => setEmail(e.target.value)} id='brandName' className='w-96 text-sm h-10 outline-none border-b border-gray-300' placeholder='Enter Brand Email'/>
                    </div>
                </div>
                <div className='flex items-center w-full'>
                    <div className='flex flex-col mb-6 mr-10'>
                        <label className='mr-4 text-lg' htmlFor='brandName'>Password:</label>
                        <input required onChange={e => setPassword(e.target.value)} id='brandName' className='w-96 text-sm h-10 outline-none border-b border-gray-300' placeholder='Enter Brand Password'/>
                    </div>
                    <div className='flex flex-col mb-6'>
                        <label className='mr-4 text-lg' htmlFor='brandName'>Contact:</label>
                        <input required onChange={e => setContact(e.target.value)} id='brandName' className='w-96 text-sm h-10 outline-none border-b border-gray-300' placeholder='Enter Brand Contact'/>
                    </div>
                </div>
                <div className='flex items-center w-full mb-6'>
                    <div className='flex flex-col mr-10'>
                        <label className='mr-4 text-lg' htmlFor='brandName'>Zip Code:</label>
                        <input required onChange={e => setZip(e.target.value)} id='brandName' className='w-96 text-sm h-10 outline-none border-b border-gray-300' placeholder='Enter Brand ZipCode'/>
                    </div>
                    <div className='flex flex-col '>
                        <label className='mr-4 text-lg' htmlFor='brandName'>Logo:</label>
                        <input onChange={e => setLogo(e.target.value)} required id='brandName' className='w-96 text-sm h-10 outline-none border-b border-gray-300' placeholder='Enter Brand Logo URL'/>
                    </div>
                </div>
                <div className='flex items-center w-full mb-6'>
                    <div className='flex flex-col mr-10'>
                        <label className='mr-4 text-lg' htmlFor='address'>Address:</label>
                        <input required onChange={e => setAddress(e.target.value)} id='address' className='w-96 text-sm h-10 outline-none border-b border-gray-300' placeholder='Enter Brand Address'/>
                    </div>
                    <div className='flex flex-col '>
                        <label className='mr-4 text-lg' htmlFor='coordinates'>Co-ordinates:</label>
                        <input onChange={e => setCords(e.target.value)} required id='coordinates' className='w-96 text-sm h-10 outline-none border-b border-gray-300' placeholder='Enter Brand Co ordinates'/>
                    </div>
                </div>
                <button className='w-36 h-10 bg-primary text-white rounded'>Create</button>
            </form>
        </div>
    )
}

export default AddBrand
