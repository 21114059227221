import React, { ComponentProps } from "react";

interface PropTypes extends ComponentProps<"input"> {
  label: string;
  isInvalid?: boolean;
  error?: string;
}

const Checkbox = ({ label, isInvalid, checked, error, ...rest }: PropTypes) => {
  return (
    <div className="form-control">
      <label className="label cursor-pointer flex items-center justify-start space-x-2">
        <input
          checked={checked}
          {...rest}
          type="checkbox"
          className="checkbox checkbox-primary"
        />
        <span className="label-text-alt"> {label}</span>
      </label>
      {isInvalid && error && (
        <div className="label">
          <span className="label-text-alt text-red-600">{error}</span>
        </div>
      )}
    </div>
  );
};

export default Checkbox;
