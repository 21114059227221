import React, { ComponentProps } from "react";

interface PropTypes extends ComponentProps<"input"> {
  label: string;
  isInvalid?: boolean;
  error?: string;
}

const Toggle = ({ label, isInvalid, error, ...rest }: PropTypes) => {
  return (
    <div className="form-control">
      <label className="label cursor-pointer">
        <span className="label-text">{label}</span>
        <input {...rest} type="checkbox" className="toggle toggle-primary" />
      </label>
      {isInvalid ? (
        <div className="label">
          <span className="label-text-alt text-red-600">{error}</span>
        </div>
      ) : null}
    </div>
  );
};

export default Toggle;
