import React, { ComponentProps } from "react";
import { twMerge } from "tailwind-merge";

interface PropTypes extends ComponentProps<"button"> {
  isLoading?: boolean;
  variant?: "solid" | "outline" | "ghost";
}

const Button = ({
  isLoading,
  disabled,
  children,
  className,
  variant = "solid",
  ...rest
}: PropTypes) => {
  return (
    <button
      {...rest}
      className={twMerge(
        "btn btn-primary",
        isLoading && "btn-disabled",
        disabled && "btn-disabled",
        variant === "solid" && " text-white",
        variant === "outline" && " btn-outline",
        variant === "ghost" && "btn-ghost",
        className
      )}
    >
      {isLoading && <span className="loading loading-spinner" />}
      {children}
    </button>
  );
};

export default Button;
