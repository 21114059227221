import React from 'react'
import pageNotFound from "../assets/undraw_page_not_found_re_e9o6.svg"

const InDevelopment = () => {
    return (
        <div className='flex flex-col w-screen h-screen items-center justify-center'>
            <img className='w-1/4' src={pageNotFound}/>
            <p className='mt-10 text-2xl'>The page is still in development</p>
        </div>
    )
}

export default InDevelopment
