import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import {
  useGetAllRequestsQuery,
  useModifyRequestMutation,
} from "../api/submissionRequests/submissionRequest";
import empty from "../assets/undraw_no_data_re_kwbl.svg";
import DataTable, { TBody, TH, THead, TR } from "../components/DataTable";
import Pagination from "../components/Pagination.js";
import Spinner from "../components/Spinner.jsx";
import ViewRequest from "../components/ViewRequest";

export interface ApiResponse {
  createdAt: string;
  driver: {
    email: string;
    fullName: string;
    gender: string;
    phone: string;
    profileImage: string;
    vehicleNumber: string;
    vehicleType: string;
    _id: string;
  };
  isAllowedToResubmit: boolean;
  reason: string;
  updatedAt: string;
  _id: string;
}

const SubmissionRequests = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const { data, isFetching } = useGetAllRequestsQuery({ page: currentPage });
  const [modifyRequest, { isLoading }] = useModifyRequestMutation();
  const [selectedRequest, setSelectedRequest] = useState<ApiResponse | null>(
    null
  );
  const [isViewSelectedModalShowing, setIsViewSelectedModalShowing] =
    useState(false);

  const handleModifyRequest = async (id: string, approve: boolean) => {
    const res = await modifyRequest({ id, isAllowedToResubmit: approve });
    console.log(res, "Res");
  };

  return (
    <>
      <div className="w-full h-full">
        <Helmet>
          <title>Submission Requests</title>
        </Helmet>
        <div className="w-80% px-6 py-2 relative left-20% flex flex-col justify-center font-inter bg-gray-100 h-screen">
          <div className="h-4/5 overflow-auto flex flex-col justify-between w-full bg-white rounded-lg">
            {isFetching ? (
              <Spinner />
            ) : !isFetching && data?.docs?.length === 0 ? (
              <div className="w-full  h-full flex flex-col items-center justify-center">
                <img className="w-1/5" src={empty} />
                <p className="mt-10 text-2xl text-gray-500 font-inter">
                  No Data Found
                </p>
              </div>
            ) : (
              <DataTable>
                <THead>
                  <TR>
                    <TH>Name</TH>
                    <TH>Email</TH>
                    <TH>Reason</TH>
                    <TH>Actions</TH>
                  </TR>
                </THead>
                <TBody>
                  {data?.docs?.map((item: ApiResponse, index: number) => (
                    <TR key={index}>
                      <TH className="flex items-center cursor-pointer">
                        <div className="w-8 h-8 border  border-black rounded-full">
                          <img
                            onError={(e) => {
                              const target = e.target as HTMLImageElement;
                              target.src =
                                "https://www.pngfind.com/pngs/m/610-6104451_image-placeholder-png-user-profile-placeholder-image-png.png";
                            }}
                            className="w-full rounded-full h-full object-cover"
                            src={item.driver?.profileImage}
                          />
                        </div>
                        <p className="ml-2 truncate text-md text-gray-900 font-normal">
                          {item.driver?.fullName || "N/A"}
                        </p>
                      </TH>
                      <TH>{item.driver?.email || "N/A"}</TH>
                      <TH>
                        {item.reason.slice(0, 30)}
                        {item.reason.length > 30 && (
                          <button
                            onClick={() => {
                              setSelectedRequest(item);
                              setIsViewSelectedModalShowing(true);
                            }}
                          >
                            ...Read More
                          </button>
                        )}
                      </TH>
                      <TH>
                        {!item.isAllowedToResubmit ? (
                          <button
                            disabled={isLoading}
                            onClick={() => handleModifyRequest(item._id, true)}
                            className={`bg-primary text-white rounded-lg w-32 py-2`}
                          >
                            Approve Request
                          </button>
                        ) : (
                          <button
                            disabled={isLoading}
                            onClick={() => handleModifyRequest(item._id, false)}
                            className=" text-red-600"
                          >
                            Reject Request
                          </button>
                        )}
                      </TH>
                    </TR>
                  ))}
                </TBody>
              </DataTable>
            )}
          </div>
          <div className="mt-6">
            <Pagination
              page={data?.totalPages}
              handlePageClick={(e: { selected: number }) => {
                setCurrentPage(e.selected + 1);
              }}
            />
          </div>
        </div>
      </div>
      <ViewRequest
        isVisible={isViewSelectedModalShowing}
        request={selectedRequest}
        onClose={() => {
          setSelectedRequest(null);
          setIsViewSelectedModalShowing(false);
        }}
      />
    </>
  );
};

export default SubmissionRequests;
