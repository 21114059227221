import Cookies from "js-cookie";
import { BASE_URL } from "./config";

export const formatPrices = (price: number) => {
  return `$ ${price?.toFixed(2)}`;
};

export const formatDate = (inputDate: string) => {
  const date = new Date(inputDate);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so we add 1
  const day = String(date.getDate()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
};

export const prepareHeaders = (headers: Headers) => {
  const token = Cookies.get("token");
  if (token) {
    headers.set("Authorization", `Bearer ${token}`);
  }
  return headers;
};

export const capitalize = (value: string) => {
  return value.slice(0, 1).toUpperCase() + value.slice(1, value.length);
};

export const isEmptyValue = (value: any) => {
  if (typeof value === "object" && value !== null && "value" in value) {
    return value.value.trim() === "";
  }
  return typeof value === "string" && value.trim() === "";
};

export const allValuesPresent = (object: any) =>
  Object.values(object).every((value) => !isEmptyValue(value));

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const validateEmail = (email: string) => {
  return emailRegex.test(email);
};

export const fetchBaseQueryHelper = {
  baseUrl: BASE_URL,
  prepareHeaders: prepareHeaders,
};

export const isFileSizeValid = (file: File): boolean => {
  const maxSizeInBytes = 1 * 1024 * 1024; // 1 MB in bytes
  return file.size <= maxSizeInBytes;
};
