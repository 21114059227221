import React, { useEffect } from 'react'
import WrappedMap from './Map'
import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import Image from "./Image"
import EnlargedImage from './Image'

const DriverDetailsModal = ({isVisible, setVisible, user}) => {

    const cancelButtonRef = useRef(null)
    const [zoomed, setZoomed] = useState(false)
    const [image, setImage] = useState("")

    useEffect(() => {
        console.log(user)
    }, [user])

    return (
      <>
    <Transition.Root show={isVisible} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={setVisible}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white w-full flex px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="w-full h-full">
                <div className="w-full border-b border-b-black h-1/4 flex items-center justify-start px-4 flex">
                    <div className="w-14 h-14 rounded-full mr-4 mb-2 flex-shrink-0">
                        <img onClick={e => {
                          setImage(user?.profileImage)
                          setZoomed(true)
                        }} onError={e => e.target.src = "https://www.pngfind.com/pngs/m/610-6104451_image-placeholder-png-user-profile-placeholder-image-png.png"} className="h-full rounded-full w-full object-cover" src={user?.profileImage}/>
                    </div>
                    <p className="text-xl font-semibold">{user?.fullName}</p>
                </div>
                <div className="h-3/4 pl-6 mt-4 flex flex-col justify-center w-full">
                    <p className="text-xl font-semibold">Contact Info</p>
                    {/* <div className="mb-4 mt-4">
                        <p className="text-base text-gray-500">Username</p>
                        <h3 className="text-base font-semibold">{user?.username}</h3>
                    </div> */}
                    <div className="mb-4 mt-4">
                        <p className="text-base text-gray-500">Gender</p>
                        <h3 className="text-base font-semibold">{user?.gender}</h3>
                    </div>
                    <div className="mb-4">
                        <p className="text-base text-gray-500">E-Mail</p>
                        <h3 className="text-base font-semibold">{user?.email}</h3>
                    </div>
                    <div className="mb-4">
                        <p className="text-base text-gray-500">Phone</p>
                        <h3 className="text-base font-semibold">{user?.phone}</h3>
                    </div>
                </div>
                </div>
                
              </div>
              <div className='w-full px-10 mb-4 flex justify-end'>
                <button onClick={() => setVisible(false)} className='w-32 h-10 rounded-lg bg-gray-200'>close</button>
              </div>

            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
    <EnlargedImage isVisible={zoomed} setVisible={setZoomed} src={image} />
    </>
        // <div className={`w-60% ${isVisible === false ?  'z-0' : "z-50"} font-inter h-3/5 ${isVisible===false ?"opacity-0": "opacity-100" } transition-all  duration-500 ease bg-white rounded-lg flex fixed top-1/2  left-1/2 transform -translate-x-1/2 -translate-y-1/2`}>
        //     <div className="w-70% h-full border-r border-gray">
        //         <div className="w-full border-b border-b-black h-1/4 flex items-center justify-start px-4 flex">
        //             <div className="w-14 h-14 rounded-full mr-4">
        //                 <img className="h-full rounded-full w-full object-cover" src="https://media.istockphoto.com/photos/put-more-in-get-more-out-picture-id1291318636?b=1&k=20&m=1291318636&s=170667a&w=0&h=UvVIk7wwkN3X9OFm8gBlWWviV5vAjfrq2ejYP30JmnA="/>
        //             </div>
        //             <p className="text-xl font-semibold">Hussam Khan</p>
        //         </div>
        //         <div className="h-3/4 pl-6 flex flex-col justify-center w-full">
        //             <p className="text-xl font-semibold">Contact Info</p>
        //             <div className="mb-4 mt-4">
        //                 <p className="text-base text-gray-500">Location</p>
        //                 <h3 className="text-base font-semibold">Islamabad, Pakistan</h3>
        //             </div>
        //             <div className="mb-4">
        //                 <p className="text-base text-gray-500">E-Mail</p>
        //                 <h3 className="text-base font-semibold">hussamkhan98@gmail.com</h3>
        //             </div>
        //             <div className="mb-4">
        //                 <p className="text-base text-gray-500">Phone</p>
        //                 <h3 className="text-base font-semibold">+0938595498</h3>
        //             </div>
        //         </div>
        //     </div>
        //     <div className="w-30% h-full">
        //         <WrappedMap
        //         googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyB3c_Amvqbsf25jKvZZ2qNIbgFT8aHNtjA"
        //         loadingElement={<div className="h-1/2 w-full"/>}
        //         containerElement={<div className="h-full w-full"/>}
        //         mapElement={<div className="h-full w-full"/>}
        //         />
        //     </div>
        //     <div onClick={() => setVisible(false)} className="relative w-10 h-10 left-8 bottom-4 bg-white flex items-center justify-center rounded-full cursor-pointer ">
        //         <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        //             <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
        //         </svg>
        //     </div>
        // </div>
    )
}

export default DriverDetailsModal
