import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { AiOutlineWarning } from "react-icons/ai";
import Spinner from "../components/Spinner.jsx";

type PropTypes = {
  isVisible: boolean;
  onHide: () => void;
  handleClick?: () => void;
  text: string;
  messageMode?: boolean;
  closeButtonText?: string;
  isSubmitButtonDisabled?: boolean;
  isSubmitting?: boolean;
};

const ConfirmationDialog = ({
  isVisible,
  onHide,
  handleClick,
  text,
  messageMode,
  closeButtonText,
  isSubmitButtonDisabled = false,
  isSubmitting = false,
}: PropTypes) => {
  return (
    <Transition.Root show={isVisible} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={onHide}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white w-full flex px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="w-full flex justify-center items-center text-center h-full">
                  <div className="h-3/4 pl-6 mt-4 flex flex-col  w-full">
                    <AiOutlineWarning
                      className="self-center mb-4"
                      size="3rem"
                    />
                    <p className="text-xl font-semibold">{text}</p>
                  </div>
                </div>
              </div>
              <div className="w-full px-10 mb-4 flex justify-center">
                {!messageMode ? (
                  <button
                    disabled={isSubmitButtonDisabled}
                    onClick={handleClick}
                    className={`w-32 h-10 flex items-center justify-center rounded-lg bg-red-600 text-white mr-4 ${
                      isSubmitButtonDisabled
                        ? "opacity-50 cursor-not-allowed"
                        : "opacity-100 cursor-pointer"
                    }`}
                  >
                    {isSubmitting && <Spinner variant="svg" />}
                    Yes
                  </button>
                ) : null}

                <button
                  onClick={onHide}
                  className="w-32 h-10 rounded-lg bg-gray-200"
                >
                  {closeButtonText || " No, close"}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ConfirmationDialog;
