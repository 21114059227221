import React, { useEffect, useState } from 'react'
import ApiManager from '../utils/ApiManager'

const AddBrand = () => {
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState([])
    const [phoneNumber, setPhoneNumber] = useState([])
    const [address, setAddress] = useState("")
    const [zipCode, setZipCode] = useState(0)

    const [type, setType] = useState("individual")


    return (
        <div className='w-80% flex-col relative bg-gray-100 left-20% min-h-screen py-10 flex justify-center px-10'>
            <h1 className='text-3xl font-semibold mb-4'>Create New Service</h1>
            <form className='w-full'>
            <div className="flex flex-col mt-10">
                <label htmlFor="type">Type:</label>
                <select
                defaultValue=""
                id="type"
                value={type}
                onChange={e => setType(e.target.value)}
                // onChange={e => setPast(e.target.value)}
                    className='w-56 mt-4 outline-none bg-white rounded h-10 pl-4 pr-2'
                >
                    {/* <option value="" disabled hidden>Type</option> */}
                    <option value="individual">Individual</option>
                    <option value="curb">Curb</option>
                </select>
            </div>
            
            {/* {products.map((item, i) => (
                <div className="mt-10">
                    
                    <div className="flex items-center mb-6">
                        <h1 className="text-2xl">Item</h1>
                        <button onClick={handleItemsAdd} className="ml-4 h-10 px-4 text-white rounded bg-primary" type="button">Add Another Item</button>
                        {i !== 0 && <button type="button" onClick={() => handleItemRemove(i)} className="ml-6 h-10 px-4 bg-red-600 text-white rounded">Remove Item</button>}
                    </div>
                    <div className="flex items-center">
                        <div className="flex flex-col">
                            <label htmlFor="productName">Product Name</label>
                            <input onChange={e => handleChange(i, "name", e.target.value)} name="name" required id="productName" className="h-10 bg-white rounded w-96 mt-4 px-4" placeholder="Enter Product Name"/>
                        </div>
                        <div className="flex flex-col ml-8">
                            <label htmlFor="imageUrl">Public Image Urls</label>
                            <input onChange={e => handleChange(i, "publicImgUrls", [e.target.value])} required id="imageUrl" className="h-10 bg-white rounded w-96 mt-4 px-4" placeholder="Enter Public Image Urls"/>
                        </div>
                    </div>
                    <div className="flex items-center mt-4">
                        <div className="flex flex-col">
                            <label htmlFor="pricePerItem">Price Per Item</label>
                            <input type="number" onChange={e => handleChange(i, "pricePerItem", e.target.value)} required id="pricePerItem" className="h-10 bg-white rounded w-96 mt-4 px-4" placeholder="Enter Price Per Item"/>
                        </div>
                        <div className="flex flex-col ml-8">
                            <label htmlFor="quantity">Qauntity</label>
                            <input type="number" onChange={e => handleChange(i, "qty", e.target.value)} required id="quantity" className="h-10 bg-white rounded w-96 mt-4 px-4" placeholder="Quantity"/>
                        </div>
                    </div>
                    <div className="flex items-center mt-4">
                        <div className="flex flex-col">
                            <label htmlFor="id">ID</label>
                            <input onChange={e => handleChange(i, "id", e.target.value)} required id="id" className="h-10 bg-white rounded w-96 mt-4 px-4" placeholder="Enter Id"/>
                        </div>
                        <div className="flex flex-col ml-8">
                            <label htmlFor="productUrl">Product Public Url</label>
                            <input onChange={e => handleChange(i, "productPublicUrl", e.target.value)} required id="productUrl" className="h-10 bg-white rounded w-96 mt-4 px-4" placeholder="Enter Product Url"/>
                        </div>
                    </div>

                </div>
            ))}  */}
            {type === "individual" ? (

            <div className="mt-10">
                <div className="flex items-center">
                    <div className="flex flex-col mt-4 mr-8">
                        <label htmlFor="firstName">First Name</label>
                        <input onChange={e => setFirstName(e.target.value)} required id="firstName" className="h-10 bg-white rounded w-96 mt-4 px-4" placeholder="Enter First Name"/>
                    </div>
                    <div className="flex flex-col mt-4">
                        <label htmlFor="lastName">Last Name</label>
                        <input onChange={e => setLastName(e.target.value)} required id="lastName" className="h-10 bg-white rounded w-96 mt-4 px-4" placeholder="Enter Last Name"/>
                    </div>
                </div>
                <div className="flex items-center">
                    {/* <div className="flex flex-col mt-4 mr-8">
                        <label htmlFor="email">Email</label>
                        <input onChange={e => setEmail([e.target.value])} required id="email" className="h-10 bg-white rounded w-96 mt-4 px-4" placeholder="Enter User Email"/>
                    </div> */}
                    <div className="flex flex-col mt-4 mr-8">
                        <label>Description</label>
                        <input className="h-10 bg-white rounded w-96 mt-4 px-4" placeholder="Enter Description"/>
                    </div>
                    <div className="flex flex-col mt-4">
                        <label htmlFor="phoneNumber">Phone Number</label>
                        <input value={phoneNumber[0]} type="number" onChange={e => setPhoneNumber([e.target.value])} required id="phoneNumber" className="h-10 bg-white rounded w-96 mt-4 px-4" placeholder="Enter Phone Number"/>
                    </div>
                </div>
                <div className="flex items-center">
                    <div className="flex w-96 flex-col mt-4 mr-8">
                        <label className="mb-2" >Item Photos</label>
                        <input type="file" accept="image/*" multiple />
                    </div>
                    {/* <div className="flex flex-col mt-4">
                        <label htmlFor="street">Street</label>
                        <input  onChange={e => setAddress([...address, e.target.value])} required id="street" className="h-10 bg-white rounded w-96 mt-4 px-4" placeholder="Enter User Street"/>
                    </div> */}
                    <div className="flex  flex-col mt-4 mr-8">
                        <label htmlFor="zipCode">ZipCode</label>
                        <input type="number" onChange={e => setZipCode(e.target.value)} required id="zipCode" className="h-10 bg-white rounded w-96 mt-4 px-4" placeholder="Enter Zip Code"/>
                    </div>
                </div>
                {/* <div className="flex items-center">
                    
                </div> */}
                <div className="flex items-center ">
                    <div className="flex flex-col mt-4 mr-8">
                        <label htmlFor="rfirstName">Recipient First Name</label>
                        <input required id="rfirstName" className="h-10 bg-white rounded w-96 mt-4 px-4" placeholder="Enter First Name"/>
                    </div>
                    <div className="flex flex-col mt-4">
                        <label htmlFor="rlastName">Recipient Last Name</label>
                        <input required id="rlastName" className="h-10 bg-white rounded w-96 mt-4 px-4" placeholder="Enter Last Name"/>
                    </div>
                </div>
                <div className="flex items-center">
                    <div className="flex flex-col mt-4 mr-8">
                        <label htmlFor="rphone">Recipient Phone Number</label>
                        <input type="number" required id="rphone" className="h-10 bg-white rounded w-96 mt-4 px-4" placeholder="Enter Recipient Phone Number"/>
                    </div>
                    <div className="flex flex-col mt-4">
                        <label htmlFor="link">Link For Confirmation</label>
                        <input required id="link" className="h-10 bg-white rounded w-96 mt-4 px-4" placeholder="Enter Link For Confirmation"/>
                    </div>
                </div>
            </div>
            ) : (
                <div className="mt-10">
                    <div className="flex items-center">
                        <div className="flex flex-col mt-4 mr-8">
                            <label htmlFor="lat">Order Number</label>
                            <input required id="lat" className="h-10 bg-white rounded w-96 mt-4 px-4" placeholder="Enter Order Number"/>
                        </div>
                        <div className="flex flex-col mt-4">
                            <label htmlFor="lastName">Store Name</label>
                            <input required id="lastName" className="h-10 bg-white rounded w-96 mt-4 px-4" placeholder="Enter Store Name"/>
                        </div>
                    </div>
                    <div className="flex items-center">
                        <div className="flex flex-col mt-4 mr-8">
                            <label htmlFor="lat">Store Website</label>
                            <input required id="lat" className="h-10 bg-white rounded w-96 mt-4 px-4" placeholder="Enter Store Website"/>
                        </div>
                        <div className="flex flex-col mt-4">
                            <label htmlFor="noofitems">Num Of Items</label>
                            <input min={1} type='number' required id="noofitems" className="h-10 bg-white rounded w-96 mt-4 px-4" placeholder="Enter Number Of Items"/>
                        </div>
                    </div>
                    <div className="flex items-center">
                        <div className="flex flex-col mt-4 mr-8">
                            <label htmlFor="lat">Description</label>
                            <input required id="lat" className="h-10 bg-white rounded w-96 mt-4 px-4" placeholder="Enter Description"/>
                        </div>
                        <div className="flex flex-col mt-4">
                            <label htmlFor="lastName">Link For Confirmation</label>
                            <input required id="lastName" className="h-10 bg-white rounded w-96 mt-4 px-4" placeholder="Enter Link For Confirmation"/>
                        </div>
                    </div>
                </div>
            )}
            <div className="mt-10">
                <h1 className="text-3xl">Pickup Location</h1>
                <div className="flex items-center">
                    <div className="flex flex-col mt-4 mr-8">
                        <label htmlFor="lat">Lat</label>
                        <input type="number" required id="lat" className="h-10 bg-white rounded w-96 mt-4 px-4" placeholder="Enter Lat"/>
                    </div>
                    <div className="flex flex-col mt-4">
                        <label htmlFor="lastName">Long</label>
                        <input type='number' required id="lastName" className="h-10 bg-white rounded w-96 mt-4 px-4" placeholder="Enter Long"/>
                    </div>
                </div>
                <div className="flex items-center">
                    <div className="flex flex-col mt-4 mr-8">
                        <label htmlFor="lat">Location Name</label>
                        <input required id="lat" className="h-10 bg-white rounded w-96 mt-4 px-4" placeholder="Enter Location Name"/>
                    </div>
                    <div className="flex flex-col mt-4">
                        <label htmlFor="lastName">Address</label>
                        <input required id="lastName" className="h-10 bg-white rounded w-96 mt-4 px-4" placeholder="Enter Address"/>
                    </div>
                </div>
                <div className="flex items-center">
                    <div className="flex flex-col mt-4 mr-8">
                        <label htmlFor="lat">ZipCode</label>
                        <input type="number" required id="lat" className="h-10 bg-white rounded w-96 mt-4 px-4" placeholder="Enter Zipcode"/>
                    </div>
                    <div className="flex flex-col mt-4">
                        <label htmlFor="lastName">City</label>
                        <input required id="lastName" className="h-10 bg-white rounded w-96 mt-4 px-4" placeholder="Enter City Name"/>
                    </div>
                </div>
                <div className="flex items-center">
                    <div className="flex flex-col mt-4 mr-8">
                        <label htmlFor="lat">State</label>
                        <input required id="lat" className="h-10 bg-white rounded w-96 mt-4 px-4" placeholder="Enter State"/>
                    </div>
                    <div className="flex flex-col mt-4">
                        <label htmlFor="lastName">Country</label>
                        <input required id="lastName" className="h-10 bg-white rounded w-96 mt-4 px-4" placeholder="Enter Country"/>
                    </div>
                </div>
            </div>
            <div className="mt-10">
                <h1 className="text-3xl">Delivery Location</h1>
                <div className="flex items-center">
                    <div className="flex flex-col mt-4 mr-8">
                        <label htmlFor="lat">Lat</label>
                        <input type="number" required id="lat" className="h-10 bg-white rounded w-96 mt-4 px-4" placeholder="Enter Lat"/>
                    </div>
                    <div className="flex flex-col mt-4">
                        <label htmlFor="lastName">Long</label>
                        <input type='number' required id="lastName" className="h-10 bg-white rounded w-96 mt-4 px-4" placeholder="Enter Long"/>
                    </div>
                </div>
                <div className="flex items-center">
                    <div className="flex flex-col mt-4 mr-8">
                        <label htmlFor="lat">Location Name</label>
                        <input required id="lat" className="h-10 bg-white rounded w-96 mt-4 px-4" placeholder="Enter Location Name"/>
                    </div>
                    <div className="flex flex-col mt-4">
                        <label htmlFor="lastName">Address</label>
                        <input required id="lastName" className="h-10 bg-white rounded w-96 mt-4 px-4" placeholder="Enter Address"/>
                    </div>
                </div>
                <div className="flex items-center">
                    <div className="flex flex-col mt-4 mr-8">
                        <label htmlFor="lat">ZipCode</label>
                        <input type="number" required id="lat" className="h-10 bg-white rounded w-96 mt-4 px-4" placeholder="Enter Zipcode"/>
                    </div>
                    <div className="flex flex-col mt-4">
                        <label htmlFor="lastName">City</label>
                        <input required id="lastName" className="h-10 bg-white rounded w-96 mt-4 px-4" placeholder="Enter City Name"/>
                    </div>
                </div>
                <div className="flex items-center">
                    <div className="flex flex-col mt-4 mr-8">
                        <label htmlFor="lat">State</label>
                        <input required id="lat" className="h-10 bg-white rounded w-96 mt-4 px-4" placeholder="Enter State"/>
                    </div>
                    <div className="flex flex-col mt-4">
                        <label htmlFor="lastName">Country</label>
                        <input required id="lastName" className="h-10 bg-white rounded w-96 mt-4 px-4" placeholder="Enter Country"/>
                    </div>
                </div>
            </div>
                {/* <div className='flex items-center w-full'>
                    <div className='flex flex-col mb-6 mr-10'>
                        <label className='mr-4 text-lg' htmlFor='brandName'>Password:</label>
                        <input required onChange={e => setPassword(e.target.value)} id='brandName' className='w-96 text-sm h-10 outline-none border-b border-gray-300' placeholder='Enter Brand Password'/>
                    </div>
                    <div className='flex flex-col mb-6'>
                        <label className='mr-4 text-lg' htmlFor='brandName'>Contact:</label>
                        <input required onChange={e => setContact(e.target.value)} id='brandName' className='w-96 text-sm h-10 outline-none border-b border-gray-300' placeholder='Enter Brand Contact'/>
                    </div>
                </div>
                <div className='flex items-center w-full mb-6'>
                    <div className='flex flex-col mr-10'>
                        <label className='mr-4 text-lg' htmlFor='brandName'>Zip Code:</label>
                        <input required onChange={e => setZip(e.target.value)} id='brandName' className='w-96 text-sm h-10 outline-none border-b border-gray-300' placeholder='Enter Brand ZipCode'/>
                    </div>
                    <div className='flex flex-col '>
                        <label className='mr-4 text-lg' htmlFor='brandName'>Logo:</label>
                        <input onChange={e => setLogo(e.target.value)} required id='brandName' className='w-96 text-sm h-10 outline-none border-b border-gray-300' placeholder='Enter Brand Logo URL'/>
                    </div>
                </div>
                <div className='flex items-center w-full mb-6'>
                    <div className='flex flex-col mr-10'>
                        <label className='mr-4 text-lg' htmlFor='address'>Address:</label>
                        <input required onChange={e => setAddress(e.target.value)} id='address' className='w-96 text-sm h-10 outline-none border-b border-gray-300' placeholder='Enter Brand Address'/>
                    </div>
                    <div className='flex flex-col '>
                        <label className='mr-4 text-lg' htmlFor='coordinates'>Co-ordinates:</label>
                        <input onChange={e => setCords(e.target.value)} required id='coordinates' className='w-96 text-sm h-10 outline-none border-b border-gray-300' placeholder='Enter Brand Co ordinates'/>
                    </div>
                </div> */}
                <button className='w-36 h-10 bg-primary mt-10 text-white rounded'>Create</button>
            </form>
        </div>
    )
}

export default AddBrand
