import React, { useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useDeleteAdminMutation, useGetAllAdminsQuery } from "../api/admins";
import empty from "../assets/undraw_no_data_re_kwbl.svg";
import ConfirmationDialog from "../components/ConfirmationDialog";
import DataTable, { TBody, TH, THead, TR } from "../components/DataTable";
import Pagination from "../components/Pagination.js";
import Spinner from "../components/Spinner.jsx";
import { useAppSelector } from "../Redux/store";
import { ADMIN_TYPES } from "../utils/Enums";

type Admin = {
  createdAt: string;
  fcm: string[];
  firstName: string;
  lastName: string;
  updatedAt: string;
  userType: string;
  username: string;
  _id: string;
};

const userTypeMap: {
  [key: string]: string;
} = {
  admin: "Admin",
  super_admin: "Super Admin",
};

const Admins = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [selectedAdminId, setSelectedAdminId] = useState("");
  const [isDeletingAdmin, setIsDeletingAdmin] = useState(false);
  const [deleteAdmin] = useDeleteAdminMutation();
  const { data: admins, isFetching } = useGetAllAdminsQuery({
    page: currentPage,
    limit: 10,
    search,
  });

  const searchRef = useRef<HTMLInputElement>(null);

  const user = useAppSelector((state) => state.auth.user);

  const isAdmin = user?.userType === ADMIN_TYPES.ADMIN;

  const handleDeleteAdmin = async () => {
    setIsDeletingAdmin(true);
    const res: any = await deleteAdmin(selectedAdminId);
    if (res?.data) {
      toast(res?.data?.message, { type: "success" });
    } else {
      toast(res?.error?.data?.error, { type: "error" });
    }
    setSelectedAdminId("");
    setIsDeletingAdmin(false);
  };

  return (
    <>
      <Helmet>
        <title>Admins</title>
      </Helmet>
      <div className="w-full h-full">
        <div className="w-80% px-6 py-2 relative left-20% flex flex-col justify-center font-inter bg-gray-100 h-screen">
          <div className="flex items-center justify-end mb-4">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                setCurrentPage(1);
                if (searchRef.current) setSearch(searchRef.current.value);
              }}
            >
              <input
                ref={searchRef}
                className="w-52 ml-4 px-4 h-10 outline-none rounded "
                placeholder="search"
              />
              <button
                type="submit"
                className="w-32 h-10 ml-4 rounded bg-primary text-white"
              >
                Search
              </button>
            </form>
            <Link to="/dashboard/admins/add">
              <button className="ml-4 border border-primary text-primary  w-32 py-2 rounded">
                Add Admin
              </button>
            </Link>
          </div>
          <div className="h-4/5 overflow-auto flex flex-col justify-between w-full bg-white rounded-lg">
            {isFetching ? (
              <Spinner />
            ) : admins?.docs?.length === 0 ? (
              <div className="w-full h-full flex flex-col items-center justify-center">
                <img className="w-1/5" src={empty} />
                <p className="mt-10 text-2xl text-gray-500 font-inter">
                  No Admins Found
                </p>
              </div>
            ) : (
              <DataTable>
                <THead>
                  <TR>
                    <TH>First Name</TH>
                    <TH>Last Name</TH>
                    <TH>Email</TH>
                    <TH>User Type</TH>
                    <TH>Actions</TH>
                  </TR>
                </THead>
                <TBody>
                  {admins?.docs?.map((admin: Admin, index: number) => (
                    <TR key={index}>
                      <TH>{admin?.firstName || "N/A"}</TH>
                      <TH>{admin?.lastName || "N/A"}</TH>
                      <TH>{admin?.username || "N/A"}</TH>
                      <TH>{userTypeMap[admin?.userType] || "N/A"}</TH>
                      {isAdmin && admin.userType === ADMIN_TYPES.SUPER_ADMIN ? (
                        <TH>N/A</TH>
                      ) : user?._id === admin?._id ? (
                        <TH>N/A</TH>
                      ) : (
                        <TH>
                          <button
                            onClick={() => setSelectedAdminId(admin._id)}
                            className="text-red-500 m-0 p-0 font-semibold"
                          >
                            Delete
                          </button>
                        </TH>
                      )}
                    </TR>
                  ))}
                </TBody>
              </DataTable>
            )}
          </div>
          <div className="mt-6">
            <Pagination
              page={admins?.totalPages}
              handlePageClick={(e: { selected: number }) =>
                setCurrentPage(e.selected + 1)
              }
            />
          </div>
        </div>
      </div>
      <ConfirmationDialog
        handleClick={handleDeleteAdmin}
        isSubmitButtonDisabled={isDeletingAdmin}
        isSubmitting={isDeletingAdmin}
        isVisible={selectedAdminId !== ""}
        onHide={() => setSelectedAdminId("")}
        text="Are you sure you want to delete this admin?"
      />
    </>
  );
};

export default Admins;
