import { Dialog, Transition } from "@headlessui/react";
import React, { FormEvent, Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { twMerge } from "tailwind-merge";
import Spinner from "../components/Spinner.jsx";
import ApiManager from "../utils/ApiManager";

interface PropTypes {
  isVisible: boolean;
  onHide: () => void;
  onSuccess: () => void;
}

const AddStateModal = ({ isVisible, onHide, onSuccess }: PropTypes) => {
  const [allStates, setAllStates] = useState<
    { label: string; value: string }[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState({
    name: "",
    symbol: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e: FormEvent) => {
    setIsSubmitting(true);
    e.preventDefault();
    const res: any = await new ApiManager().addState(form);
    if (res.success) {
      setForm({ name: "", symbol: "" });
      toast("State Added Successfully", { type: "success" });
      onHide();
      onSuccess();
    } else {
      toast(res?.error?.response?.data?.error, { type: "error" });
    }
    setIsSubmitting(false);
  };

  const handleGetAllStates = async () => {
    setIsLoading(true);
    const res = await new ApiManager().getAllStates();
    if (res.success) {
      setAllStates(res.data?.states);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    handleGetAllStates();
  }, []);

  console.log(allStates, "all states");

  return (
    <Transition.Root show={isVisible} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={onHide}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              {isLoading ? (
                <div className="flex h-full py-10 items-center justify-center">
                  <Spinner loaderClassName="m-0" />
                </div>
              ) : (
                <form onSubmit={handleSubmit} className="py-4 px-6">
                  <h1 className="text-lg text-center font-bold">Add State</h1>
                  <div className="flex flex-col mt-4 gap-4">
                    <label htmlFor="state">State *</label>
                    <select
                      defaultValue=""
                      required
                      name="state"
                      id="state"
                      className="outline-none rounded border border-gray-300 px-4 py-2"
                      placeholder="Select a state "
                      onChange={(e) => {
                        setForm(JSON.parse(e.target.value));
                      }}
                    >
                      <option value="" disabled hidden>
                        Select a state
                      </option>
                      {allStates.map((state) => (
                        <option
                          key={state?.value}
                          value={JSON.stringify({
                            name: state?.label,
                            symbol: state?.value,
                          })}
                        >
                          {state?.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="w-full items-center justify-end flex gap-4 mt-4">
                    <button
                      disabled={isSubmitting}
                      type="submit"
                      className={twMerge(
                        "bg-primary w-32 text-white rounded py-3",
                        isSubmitting && "cursor-not-allowed opacity-80"
                      )}
                    >
                      Add
                    </button>
                    <button
                      type="button"
                      onClick={onHide}
                      className="border border-primary w-32 text-primary rounded py-3"
                    >
                      Close
                    </button>
                  </div>
                </form>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default AddStateModal;
