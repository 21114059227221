import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ApiManager from '../utils/ApiManager'
import {FaStar} from "react-icons/fa"
import Table from './Table'
import OrderModal from './OrderModal'

const OrderDetails = () => {
    const {id} = useParams()
    const [details, setDetails] = useState({})
    const [orders, setOrders] = useState([])
    const [loading, setLoading] = useState(false)
    const [orderLoading, setOrderLoading] = useState(false)
    const[brandDetails, setBrandDetails] = useState()
    const [page, setPage] = useState(1)

    const getUserDetails = async () => {
        setLoading(true)
        let res = await new ApiManager().getOrderDetails(id)
        if(res.success){
            let res2 = await new ApiManager().getBrandDetails(res.payload.data.brand)
            setBrandDetails(res2.payload.data)
            setDetails(res.payload.data)
        }
        setLoading(false)
        // console.log(details)
    }
    
    
    useEffect(() => {
        getUserDetails()
    }, [])
    
    const [isVisible, setVisible] = useState(false)

    const [order,setOrder] = useState()

    useEffect(() => {
        console.log(details)
        console.log(brandDetails)
    }, [details, brandDetails])
    
    return (
        <div className='w-80% px-6 py-10 relative left-20% font-inter flex flex-col justify-center font-inter bg-gray-100 min-h-screen'>
            <div className="flex items-center mb-4">
                <h1 className='text-3xl mr-4'>Order Details</h1>
                <span className='w-4/5 h-1 bg-gray-300'></span>
            </div>
            <div className='w-full h-[90%] bg-white rounded-lg shadow-xl flex flex-col'>
                {loading === true ? (
                    <div className="w-full h-full flex items-center justify-center">
                        <div className="loader"></div>
                    </div>
                ) : (
                <>
                <div className='w-full h-1/2 flex border-b border-gray-300'>
                    <div className='w-1/2 h-full flex flex-col items-start justify-center border-r border-gray-300 px-8'>
                            <div className='flex items-center mb-2'>
                                <p className='mr-2 text-gray-500'>Status:</p>
                                <p className='font-semibold'>{details?.status}</p>
                            </div>
                            <div className='flex items-center mb-2'>
                                <p className='mr-2 text-gray-500'>Store Order ID:</p>
                                <p className='font-semibold'>{details?.storeOrderId}</p>
                            </div>
                            <div className='flex items-center mb-2'>
                                <p className='mr-2 text-gray-500'>Created At:</p>
                                <p className='font-semibold'>{details?.createdAt?.slice(0,10)}</p>
                            </div>
                            <div className='flex items-center mb-2'>
                                <p className='mr-2 text-gray-500'>Driver:</p>
                                <p className='font-semibold'>{details?.driver === null ? "N/A" : details.driver}</p>
                            </div>
                            <div className='flex items-center mb-2'>
                                <p className='mr-2 text-gray-500'>Driver Route:</p>
                                <p className='font-semibold'>{details?.driverRoute?.length === 0 ? "N/A": details?.driverRoute?.map(route => route.latitude + ", " + route.longitude)}</p>
                            </div>
                    </div>

                    <div className='w-1/2 h-full flex flex-col px-10 py-10'>
                        <p className='text-2xl mb-4'>User Details</p>
                        <div className='flex justify-center flex-col'>
                            <div className='flex items-center mb-2'>
                                <p className='mr-2 text-gray-500'>Email:</p>
                                <p className='font-semibold'>{details?.userMeta?.email[0]}</p>
                            </div>
                            <div className='flex items-center mb-2'>
                                <p className='mr-2 text-gray-500'>Contact Numbers:</p>
                                <p className='font-semibold'>{details?.userMeta?.phoneNumber?.map(numb => {return `${numb},`})}</p>
                            </div>
                            <div className='flex items-start mb-2'>
                                <p className='mr-2 text-gray-500'>Address:</p>
                                <p className='font-semibold'>{details?.userMeta?.address?.map(numb => {return <p className='mr-2'>{numb}.</p>})}</p>
                            </div>
                            <div className='flex items-start mb-2'>
                                <p className='mr-2 text-gray-500'>Zip Code:</p>
                                <p className='font-semibold'>{details?.userMeta?.zipCode}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='w-full h-1/2 flex border-b border-gray-300'>
                    <div className='w-1/2 h-full flex flex-col items-start justify-center border-r border-gray-300 px-8'>
                            <p className='text-2xl mb-4'>Brand Details</p>
                            <div className='flex items-center mb-2'>
                                <p className='mr-2 text-gray-500'>Brand Logo:</p>
                                <img  onError={e => e.target.src =" https://www.pngfind.com/pngs/m/610-6104451_image-placeholder-png-user-profile-placeholder-image-png.png"} className='w-10 h-10 object-cover rounded-full' src={brandDetails?.brandLogo} alt='image could not be loaded'/>
                            </div>
                            <div className='flex items-center mb-2'>
                                <p className='mr-2 text-gray-500'>Brand Name:</p>
                                <p className='font-semibold'>{brandDetails?.brandName}</p>
                            </div>
                            <div className='flex items-center mb-2'>
                                <p className='mr-2 text-gray-500'>Created At:</p>
                                <p className='font-semibold'>{brandDetails?.createdAt?.slice(0,10)}</p>
                            </div>
                            <div className='flex items-center mb-2'>
                                <p className='mr-2 text-gray-500'>Email:</p>
                                <p className='font-semibold'>{brandDetails?.email}</p>
                            </div>
                            <div className='flex items-center mb-2'>
                                <p className='mr-2 text-gray-500'>Contact:</p>
                                <p className='font-semibold'>{brandDetails?.contact}</p>
                            </div>
                    </div>

                    <div className='w-1/2 h-full flex flex-col px-10 py-10'>
                        <p className='text-2xl mb-4'>User Details</p>
                        <div className='flex justify-center flex-col'>
                            <div className='flex items-center mb-2'>
                                <p className='mr-2 text-gray-500'>Email:</p>
                                <p className='font-semibold'>{details?.userMeta?.email[0]}</p>
                            </div>
                            <div className='flex items-center mb-2'>
                                <p className='mr-2 text-gray-500'>Contact Numbers:</p>
                                <p className='font-semibold'>{details?.userMeta?.phoneNumber?.map(numb => {return `${numb},`})}</p>
                            </div>
                            <div className='flex items-start mb-2'>
                                <p className='mr-2 text-gray-500'>Address:</p>
                                <p className='font-semibold'>{details?.userMeta?.address?.map(numb => {return <p className='mr-2'>{numb}.</p>})}</p>
                            </div>
                            <div className='flex items-start mb-2'>
                                <p className='mr-2 text-gray-500'>Zip Code:</p>
                                <p className='font-semibold'>{details?.userMeta?.zipCode}</p>
                            </div>
                        </div>
                    </div>
                </div>
                </>
                )}
            </div>
            {/* <div className='w-20 h-20 mb-4 rounded-full flex items-center justify-center'>
                <img onError={e => e.target.src =" https://media.istockphoto.com/photos/put-more-in-get-more-out-picture-id1291318636?b=1&k=20&m=1291318636&s=170667a&w=0&h=UvVIk7wwkN3X9OFm8gBlWWviV5vAjfrq2ejYP30JmnA="} className='w-full h-full object-cover rounded-full' src={details.profileImage} alt='image could not be loaded'/>
            </div>
            <div className='flex items-center mb-2'>
                <p className='text-lg font-semibold mr-2'>Full Name:</p>
                <p className='text-lg'>{details.fullName}</p>
            </div>
            <div className='flex items-center mb-2'>
                <p className='text-lg font-semibold mr-2'>User Name:</p>
                <p className='text-lg'>{details.username}</p>
            </div>
            <div className='flex items-center mb-2'>
                <p className='text-lg font-semibold mr-2'>Email:</p>
                <p className='text-lg'>{details.email}</p>
            </div>
            <div className='flex items-center mb-2'>
                <p className='text-lg font-semibold mr-2'>Phone:</p>
                <p className='text-lg'>{details.phone}</p>
            </div>
            <div className='flex items-center mb-2'>
                <p className='text-lg font-semibold mr-2'>Gender:</p>
                <p className='text-lg'>{details.gender}</p>
            </div>
            <div className='flex items-center mb-2'>
                <p className='text-lg font-semibold mr-2'>Rating:</p>
                <p className='text-lg'>{details.rating}</p>
            </div>
            <div className='flex items-center mb-2'>
                <p className='text-lg font-semibold mr-2'>Created At:</p>
                <p className='text-lg'>{details?.createdAt?.slice(0,10)}</p>
            </div> */}
            
        </div>
    )
}

export default OrderDetails
