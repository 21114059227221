import React from "react";
import dayjs from "dayjs";

const Timeline = ({ logs }) => {
  const statusMap = (status) => {
    const map = {
      userunassigned: {
        text: "User Created Order",
      },
      driverwayToStore: {
        text: "Driver is on his way to the store",
      },
      driverarrivedAtPickup: {
        text: "Driver has arrived to pickup the order",
      },
      driverconfirmItems: {
        text: "Driver has confirmed items",
      },
      driverproceedToDropoff: {
        text: "Driver is on his way to drop off",
      },
      driverarrivedAtDropoff: {
        text: "Driver has arrived at his destination",
      },
      driverconfirmDropoff: {
        text: "Driver has confirmed drop off",
      },
      driverdeliver: {
        text: "Driver has delivered items",
      },
      driverdelivered: {
        text: "Driver has delivered items",
      },
      userratedByUser: {
        text: "User has rated the driver",
      },
      drivercancelled: {
        text: "Driver cancelled order",
      },
      usercancelled: {
        text: "User cancelled order",
      },
      admincancelled: {
        text: "Admin Cancelled Order",
      },
    };

    const item = map[status];
    return item?.text;
  };

  return (
    <div>
      <ol className="relative border-l border-gray-200">
        {logs?.map((log) => (
          <li className="mb-10 ml-4">
            <div className="absolute w-3 h-3 bg-primary rounded-full mt-1.5 -left-1.5 border border-white"></div>
            <time className="mb-1 text-sm font-normal leading-none text-gray-400">
              {dayjs(log?.createdAt).format("DD/MM/YYYY")}{" "}
              {new Date(log?.createdAt).toLocaleTimeString("en-US")}{" "}
            </time>
            <h3 className="text-lg font-semibold text-gray-900">
              {statusMap(log?.actionBy + log?.status)}
            </h3>
          </li>
        ))}
      </ol>
    </div>
  );
};

export default Timeline;
