import React, { ChangeEvent, useRef, useState } from "react";
import { toast } from "react-toastify";
import SpecificTable from "../components/SpecificTable";
import { instance } from "../utils/ApiManager";
import Select from "react-select";

const selectOptions = [
  { label: "All", value: "all" },
  { label: "Specific", value: "specific" },
];

const SendNotification = () => {
  const [form, setForm] = useState({
    title: "",
    message: "",
    image: "",
    userType: "user",
    target: selectOptions[0],
  });
  const [isImageUploading, setImageUploading] = useState(false);
  const [showSpecificTable, setShowSpecificTable] = useState(false);
  const [targets, setTargets] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const imageInputRef = useRef<HTMLInputElement>(null);

  const handleImageUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    setImageUploading(true);
    if (!e.target.files) return;
    const file = e.target.files[0];
    const fd = new FormData();
    fd.append("file", file);
    const res = await instance.post("/upload", fd);
    if (res.data) {
      setForm((prev) => ({ ...prev, image: res.data?.Location }));
      setImageUploading(false);
    }
  };

  const handleUserTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.checked, "checked");
    if (event.target.checked) {
      setTargets([]);
      setForm({
        ...form,
        userType: event.target.name,
        target: selectOptions[0],
      });
    }
  };

  const handleSubmit = async () => {
    const { title, message, image, userType } = form;
    if (!title || !message || !userType) {
      alert("Please enter all required fields");
      return;
    }
    const body: {
      title: string;
      body: string;
      userType: string;
      specificTargets: boolean;
      targets: string[];
      image?: string;
    } = {
      title: title,
      body: message,
      userType: userType,
      specificTargets: form.target.value === "specific" ? true : false,
      targets: form.target.value === "specific" ? targets : [],
    };
    if (image) {
      body.image = image;
    }
    if (form.target.value === "specific" && targets.length === 0) {
      alert(`Please select at least one ${userType}`);
      return;
    }
    setLoading(true);
    const res = await instance.post("/admin/notifications", body);
    if (res.status === 200) {
      toast("Notification sent successfully", { type: "success" });
      setForm({
        image: "",
        message: "",
        target: selectOptions[0],
        title: "",
        userType: "",
      });
      if (imageInputRef.current) {
        imageInputRef.current.value = "";
      }
      setTargets([]);
    }
    setLoading(false);
  };

  const handleTargetChange = (option: any) => {
    setForm({ ...form, target: option });
    if (option.value === "specific") {
      setShowSpecificTable(true);
    }
  };

  const addToTarget = (id: string) => {
    const arr = [...targets];
    arr.push(id);
    setTargets(arr);
  };

  const removeFromTarget = (id: String) => {
    const arr = [...targets];
    const index = arr.findIndex((item) => item === id);
    arr.splice(index, 1);
    setTargets(arr);
  };

  return (
    <>
      <div className="w-80% z-10 px-6 relative left-20% flex flex-col justify-center font-inter bg-gray-100 h-screen">
        <div className="text-xl mb-10 font-semibold">Send Notification</div>
        <div className="flex flex-col">
          <label htmlFor="title">Title *</label>
          <input
            className="w-96 px-4 rounded outline-none py-3 mt-2"
            type="text"
            onChange={(e) => setForm({ ...form, title: e.target.value })}
            id="title"
            value={form.title}
            placeholder="Enter Title"
          />
        </div>
        <div className="flex flex-col mt-4">
          <label htmlFor="image">Image </label>
          <input
            className="w-96 px-4 bg-white rounded outline-none py-2 mt-2"
            type="file"
            id="image"
            ref={imageInputRef}
            onChange={handleImageUpload}
            accept="image/*"
          />
          {isImageUploading && (
            <p className="mt-4">Uploading Image, Please wait...</p>
          )}
        </div>
        <div className="flex flex-col mt-4">
          <label htmlFor="message">Message *</label>
          <textarea
            value={form.message}
            className="w-96 px-4 bg-white rounded outline-none py-2 mt-2"
            id="message"
            onChange={(e) => setForm({ ...form, message: e.target.value })}
          />
        </div>
        <div className="flex flex-col mt-4">
          <label htmlFor="userType" className="mb-4">
            User Type *
          </label>
          <div className="flex items-center gap-2">
            <div className="flex items-center gap-2">
              <input
                name="user"
                onChange={handleUserTypeChange}
                type="radio"
                checked={form.userType === "user"}
                id="user"
              />
              <label className="text-sm" htmlFor="user">
                User
              </label>
            </div>
            <div className="flex items-center gap-2">
              <input
                onChange={handleUserTypeChange}
                type="radio"
                name="driver"
                id="driver"
                checked={form.userType === "driver"}
              />
              <label className="text-sm" htmlFor="driver">
                Driver
              </label>
            </div>
          </div>
        </div>
        {form.userType && (
          <div className="flex flex-col mt-4">
            <p className="mb-4">All or Specific *</p>
            <Select
              className="w-96"
              value={form.target}
              onChange={(e) => handleTargetChange(e)}
              options={selectOptions}
            />
          </div>
        )}
        <button
          disabled={isImageUploading}
          onClick={handleSubmit}
          className={`${
            isImageUploading || loading
              ? "bg-gray-300 cursor-not-allowed"
              : "bg-primary cursor-pointer"
          }  w-32 mt-10 text-white rounded px-4 py-2`}
        >
          Send
        </button>
      </div>
      <SpecificTable
        addToTarget={addToTarget}
        isOpen={showSpecificTable}
        removeFromTarget={removeFromTarget}
        closeModal={() => setShowSpecificTable(false)}
        userType={form.userType}
        targets={targets}
      />
    </>
  );
};

export default SendNotification;
