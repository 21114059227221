import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import {
  useDeleteNotificationMutation,
  useGetAllNotificationsQuery,
} from "../api/notifications/notifications";
import empty from "../assets/undraw_no_data_re_kwbl.svg";
import ConfirmationDialog from "../components/ConfirmationDialog";
import DataTable, { TBody, TH, THead, TR } from "../components/DataTable";
import Pagination from "../components/Pagination.js";
import Spinner from "../components/Spinner.jsx";
import { capitalize } from "../utils/helpers";
import ViewNotification from "../components/ViewNotification";

export type NotificationType = {
  title: string;
  body: string;
  status: string;
  _id: string;
  userType: string;
  __v: number;
  targets: string[];
  createdAt: string;
  updatedAt: string;
};

const Notification = () => {
  const [app, setApp] = useState("user");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedNotificationId, setSelectedNotificationId] = useState("");
  const [selectedNotification, setSelectedNotification] =
    useState<NotificationType | null>(null);

  const { data: notifications, isFetching: isLoading } =
    useGetAllNotificationsQuery({
      userType: app,
      limit: 10,
      page: currentPage,
    });

  const [deleteNotification, { isLoading: isDeletingNotification }] =
    useDeleteNotificationMutation();

  const handlePageClick = (e: { selected: number }) => {
    setCurrentPage(e.selected + 1);
  };

  const handleDeleteNotification = async () => {
    const res: any = await deleteNotification(selectedNotificationId);
    if (res?.data?.success === true) {
      setSelectedNotificationId("");
    }
  };

  return (
    <>
      <Helmet>
        <title>Notifications</title>
      </Helmet>
      <div className="w-full h-full">
        <div className="w-80% px-6 py-2 relative left-20% flex flex-col justify-center font-inter bg-gray-100 h-screen">
          <div className="flex items-center justify-end mb-4">
            <div className="flex items-center">
              <div className="px-3 py-3 bg-white rounded-full">
                <button
                  onClick={() => setApp("user")}
                  className={`${
                    app === "user"
                      ? "bg-primary text-white"
                      : "bg-transparent text-primary"
                  } rounded-full w-32 py-2 relative`}
                >
                  Users
                </button>
                <button
                  onClick={() => setApp("driver")}
                  className={`${
                    app === "driver"
                      ? "bg-primary text-white"
                      : "bg-transparent text-primary"
                  } rounded-full w-32 py-2 relative`}
                >
                  Drivers
                </button>
              </div>
              <Link to="/dashboard/send-notification">
                <button className="border border-primary text-primary px-4 py-3 rounded ml-4">
                  Send Notification
                </button>
              </Link>
            </div>
          </div>
          <div className="h-4/5 overflow-auto flex flex-col justify-between w-full bg-white rounded-lg">
            {isLoading ? (
              <Spinner />
            ) : !isLoading && notifications?.docs?.length === 0 ? (
              <div className="w-full  h-full flex flex-col items-center justify-center">
                <img className="w-1/5" src={empty} />
                <p className="mt-10 text-2xl text-gray-500 font-inter">
                  No Notifications Found
                </p>
              </div>
            ) : (
              <DataTable>
                <THead>
                  <TR>
                    <TH>Title</TH>
                    <TH>Body</TH>
                    <TH>Status</TH>
                    <TH>User Type</TH>
                    <TH>Actions</TH>
                  </TR>
                </THead>
                <TBody>
                  {notifications?.docs?.map((noti: NotificationType) => (
                    <TR key={noti?._id}>
                      <TH>{noti?.title || "N/A"}</TH>
                      <TH>
                        <div className="relative group flex items-center">
                          {noti?.body ? noti?.body.slice(0, 40) : "N/A"}{" "}
                          {noti?.body.length > 40 ? (
                            <p
                              onClick={() => setSelectedNotification(noti)}
                              className="text-xs cursor-pointer"
                            >
                              ...Read More
                            </p>
                          ) : null}{" "}
                        </div>
                      </TH>
                      <TH>{noti?.status ? capitalize(noti?.status) : "N/A"}</TH>
                      <TH>
                        {noti?.userType ? capitalize(noti?.userType) : "N/A"}
                      </TH>
                      <TH>
                        <button
                          onClick={() => setSelectedNotificationId(noti?._id)}
                          className={`text-red-500  ${
                            isDeletingNotification
                              ? "opacity-75 cursor-not-allowed"
                              : "opacity-100 cursor-pointer"
                          }`}
                          disabled={isDeletingNotification}
                        >
                          Delete
                        </button>
                      </TH>
                    </TR>
                  ))}
                </TBody>
              </DataTable>
            )}
          </div>
          <div className="mt-6">
            <Pagination
              page={notifications?.totalPages}
              handlePageClick={(e: { selected: number }) => handlePageClick(e)}
            />
          </div>
        </div>
      </div>
      <ConfirmationDialog
        text="Are you sure you want to delete this notification?"
        isVisible={selectedNotificationId !== ""}
        onHide={() => setSelectedNotificationId("")}
        handleClick={handleDeleteNotification}
        isSubmitButtonDisabled={isDeletingNotification}
      />
      <ViewNotification
        isVisible={selectedNotification !== null}
        notification={selectedNotification}
        onHide={() => setSelectedNotification(null)}
      />
    </>
  );
};

export default Notification;
