import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { fetchBaseQueryHelper } from "../../utils/helpers";

const submissionRequestsApi = createApi({
  reducerPath: "submissionRequestsApi",
  baseQuery: fetchBaseQuery(fetchBaseQueryHelper),
  tagTypes: ["SubmissionRequests"],
  endpoints: (builder) => ({
    getAllRequests: builder.query({
      query: (params) => ({
        url: "/admin/request/all",
        params: { ...params },
      }),
      providesTags: ["SubmissionRequests"],
    }),
    modifyRequest: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `/admin/request/${id}/modify`,
        body: rest,
        method: "PATCH",
      }),
      invalidatesTags: ["SubmissionRequests"],
    }),
  }),
});

export const { useGetAllRequestsQuery, useModifyRequestMutation } =
  submissionRequestsApi;

export default submissionRequestsApi;
