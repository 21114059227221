import React, { useEffect, useRef, useState } from "react";
import Sidebar from "./Sidebar";
import HiringDetailsModal from "./HiringDetailsModal";
import ApiManager from "../utils/ApiManager";
import empty from "../assets/undraw_no_data_re_kwbl.svg";
import OrdersModal from "./OrdersModal";
import { Link } from "react-router-dom";
import Pagination from "./Pagination";
import dayjs from "dayjs";
import {AiOutlineArrowLeft, AiOutlineArrowRight} from "react-icons/ai"
import { Helmet } from "react-helmet-async";


const DashboardOrders = () => {
	const [isViewApplocationVisible, setViewApplicationVisible] = useState(false);
	const [orders, setOrders] = useState([]);
	const [order, setOrder]  = useState()
	const [loading, setLoading] = useState(false);
	const [totalPages, setTotalPages] = useState(0)
	// const [sort, setSort] = useState("desc")
	const [past, setPast] = useState("")
	const [fromDate, setFromDate] = useState()
	const [toDate, setToDate] = useState()
	const [currentPage_, setCurrentPage_] = useState(0)
	const [hasNextPage, setHasNextPage] = useState(false)
	const [hasPreviousPage, setHasPrevPage] = useState(false)
	

	const [persons, setPersons] = useState(orders);
	const [currentPage, setCurrentPage] = useState(1);
	const [personsPerPage, setPersonsPerPage] = useState(10);

	const indexOfLastPerson = currentPage * personsPerPage;
	const indexOfFirstPerson = indexOfLastPerson - personsPerPage;
	const currentPersons = persons.slice(indexOfFirstPerson, indexOfLastPerson);

	const pageNumbers = [];
	const totalPersons = persons.length;

	for (let i = 1; i <= Math.ceil(totalPersons / personsPerPage); i++) {
		pageNumbers.push(i);
	}

	const paginate = pageNumber => {
		setCurrentPage(pageNumber);
	};

	const getOrders = async () => {
		setLoading(true);
		let res = await new ApiManager().getOrders();
		if (res.success) {
			console.log(res)
			setOrders(res.payload.data.docs);
			setTotalPages(res.payload.data.totalPages)
			setPersons(res.payload.data.docs);
			setCurrentPage_(res.payload.data.page)
			setHasNextPage(res.payload.data.hasNextPage)
			setHasPrevPage(res.payload.data.hasPrevPage)
		}
		setLoading(false);
	};

	useEffect(() => {
		getOrders();
	}, []);

	useEffect(() => {
		if(fromDate > toDate){
			alert("from date cannot be greater than to date")
		}
	  }, [toDate,fromDate])

	const handlePageClick = async (page) => {
		setLoading(true)
        // console.log(event)
        let res = await new ApiManager().getOrders(10, "desc" , page)
		if(res.success){
			setOrders(res?.payload.data.docs)
			setCurrentPage_(res.payload.data.page)
			setHasNextPage(res.payload.data.hasNextPage)
			setHasPrevPage(res.payload.data.hasPrevPage)
		}
		console.log(res)
		setLoading(false)
      };

	  const form = useRef()

	  const filterOrders = async (e) => {
		e.preventDefault()
		setLoading(true)
		const res = await new ApiManager().getOrders(10, "desc",currentPage_, dayjs(fromDate).format("MM-DD-YYYY"), dayjs(toDate).format("MM-DD-YYYY"), null, null, null, past)
		if(res.success){
			setOrders(res.payload.data.docs)
			setTotalPages(res.payload.data.totalPages)
			setCurrentPage_(res.payload.data.page)
			setHasNextPage(res.payload.data.hasNextPage)
			setHasPrevPage(res.payload.data.hasPrevPage)
		}
		setLoading(false)
	  }

	return (
		<div className='w-full h-full'>
			{/* <Sidebar/> */}
			<Helmet>
				<title>Orders Dashboard</title>
			</Helmet>
			{loading === false && orders?.length === 0 ? (
				<div className='w-80% flex-col relative bg-gray-100 left-20% h-screen flex items-center justify-center'>
					<img className='w-96' src={empty} />
					<p className='mt-10 text-2xl text-gray-500 font-inter'>No Orders Found</p>
				</div>
			) : (
				<div className='w-80% px-6 relative left-20% flex flex-col justify-center font-inter bg-gray-100 h-screen'>
					<div className='w-full h-20 flex justify-between items-center'>
						<p className='text-xl font-semibold '>Orders</p>
						<form  onSubmit={e => filterOrders(e)} ref={form} className="w-full flex items-center justify-end mb-4">
							<select
							defaultValue=""
							// onChange={e => setPast(e.target.value)}
								className='w-32 outline-none bg-white rounded-lg h-10 pl-4 pr-2 ml-4'
							>
								<option value="" disabled hidden>Type</option>
								<option value="2">Individual</option>
								<option value="1">Curb</option>

							</select>
							<select
							defaultValue=""
							onChange={e => setPast(e.target.value)}
								className='w-32 outline-none bg-white rounded-lg h-10 pl-4 pr-2 ml-4'
							>
								<option value="" disabled hidden>Time</option>
								<option value="">All</option>
								<option value="1">Today</option>
								<option value="7" >Last Week</option>
								<option value="30">Last Month</option>
							</select>
							{/* <select
								defaultValue=""
								onChange={e => setSort(e.target.value)}
								className='w-32 outline-none bg-white rounded-lg h-10 pl-4 pr-2 ml-4 '
							>
								<option value="" disabled hidden>Sort</option>
								<option value="desc">Descending</option>
								<option value="asc">Ascending</option>
							</select> */}
							<div className='flex items-center ml-4'>
								<label htmlFor='from_date'>From: </label>
								<input onChange={e => setFromDate(e.target.value)} id='from_date' className='w-44 rounded h-10 bg-white ml-2 px-2' type='date' />
							</div>
							<div className='flex items-center ml-4'>
								<label htmlFor='to_date'>To: </label>
								<input onChange={e => setToDate(e.target.value)} id='to_date' className='w-44 rounded h-10 bg-white ml-2 px-2' type='date' />
							</div>
							<button onClick={(e) => {
								form.current.reset()
								setPast("")
								// setSort("desc")
								setFromDate("")
								setToDate("")
								// filterBrands(e)
							}} className="w-24 ml-4 h-10 bg-transparent border rounded-lg border-primary text-primary">Clear</button>
							
							<button type="submit" className="w-28 ml-4 h-10 bg-primary rounded-lg  text-white">Apply</button>
							<button type="button" onClick={() => window.location.reload(true)} className="w-32 bg-transparent border border-primary text-primary h-10 ml-4 rounded">Refresh</button>
						</form>
					</div>
					<div className='h-4/5 px-14 flex flex-col justify-between w-full bg-white rounded-lg'>
						
						<div className='w-full h-4/5 overflow-x-auto'>
							{loading && loading === true ? (
								<div className="w-full h-full flex items-center justify-center">
									<div className="loader"></div>
								</div>
							) : (
								<>
									<div className='w-full 2xl:justify-evenly flex h-24 items-center'>
									<p className='w-44 text-gray-400'>Driver</p>
									<p className='w-36  text-gray-400'>Status</p>
									<p className='w-40 mr-4 text-gray-400'>Items</p>
									<p className='w-40 text-gray-400'>Store Order Id</p>
									<p className='w-40  text-gray-400'>Brand</p>
									<p className='w-40  text-gray-400'>Type</p>
									<p
										className='2xl:w-20 flex-shrink-0  text-primary cursor-pointer font-semibold mr-8'
									>
										
									</p>
								{/* <p className="w-44  text-gray-400">Brand Logo</p> */}
								</div>
								{orders?.map(order => (
								<div className='w-full 2xl:justify-evenly flex min-h-14% items-center border-b border-gray-200 py-2'>
									<Link to={`/dashboard/orders/${order._id}`}>
										<div className='w-44 flex flex-shrink-0 items-center h-full'>
											{/* <div className='w-8 h-8 rounded-full'>
												<img className='w-full object-cover' src={freelancer.image} />
											</div> */}
											<p className='text-md break-all font-semibold'>{order?.driver?.length === 0 ? "N/A" : order?.driver[0]?.fullName}</p>
										</div>
									</Link>
									<p className='w-36 flex flex-shrink-0 text-md text-gray-400 break-all'>
										{order.status}
									</p>
									<p className='w-40 mr-4 break-all flex flex-shrink-0 text-md text-gray-400'>{order.items[0].name} & {order.items.length - 1} more</p>
									<p className='w-32 break-all flex-shrink-0 pl-4 font-semibold mr-8'>{order.storeOrderId}</p>
									<p className='w-32 break-all flex-shrink-0  font-semibold mr-8'>{order.brand.length > 1 ? `${order.brand[0].brandName} & ${order.brand.length - 1} more`: order.brand[0].brandName}</p>
									<p className='w-32 break-all flex-shrink-0  font-semibold mr-8'>Curb</p>
									{/* <p className="w-20 flex-shrink-0  font-semibold mr-8">
                                    <img className='w-1/2 ' src='https://static.rock.so/file/4X20DN1p/NjODYRvK/9e3c1ac42b544810a8960ba5329d09c5/t-Swyvery-02.png'/>
                                </p> */}
									<p
										onClick={() => {
											setOrder(order)
											setViewApplicationVisible(true)}
										}
										className='w-20 flex-shrink-0  text-primary cursor-pointer font-semibold mr-8'
									>
										Details
									</p>
								</div>
								))
									}
								</>
							)}
						</div>
						<div className='flex mt-4 mb-8 w-full justify-center items-center'>
							{/* <Pagination handlePageClick={handlePageClick}  page={totalPages}/> */}
							<div onClick={() => {
								if(hasPreviousPage === true){
									handlePageClick(currentPage_ - 1)}
								}
								} className={`block py-2 ${hasPreviousPage === false && "cursor-not-allowed"} px-3 cursor-pointer leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white`}>
								<AiOutlineArrowLeft/>
							</div>
							{[...Array(totalPages)].map((page, i) => (
								<div onClick={() => {
									if(i + 1 !== currentPage_){
										handlePageClick(i + 1)
									}
									}} className={`py-2 px-3 ${i + 1 === currentPage_ && "text-black font-bold"} cursor-pointer leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white`}>
									{i + 1}
								</div>
							))}
							
							
							<div onClick={() => {
								if(hasNextPage === true)
								handlePageClick(currentPage_ + 1)}
								} className={`block py-2 ${hasNextPage === false && "cursor-not-allowed"} px-3 cursor-pointer leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white`}>
								<AiOutlineArrowRight/>
							</div>
						</div>
					</div>
				</div>
			)}
			<OrdersModal
				isVisible={isViewApplocationVisible}
				setVisible={setViewApplicationVisible}
				user={order}
			/>
		</div>
	);
};

export default DashboardOrders;
