import React, { useState } from "react";
import { FaStar } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { useGetUserDetailsQuery } from "../api/users/users";
import BackButton from "../components/BackButton.js";
import EnlargedImage from "../components/Image.js";
import Table from "../components/Table";

const UserDetails = () => {
  const { id } = useParams();
  const [image, setImage] = useState("");
  const [zoomed, setZoomed] = useState(false);

  const { data: details, isFetching: loading } = useGetUserDetailsQuery(id);

  const approvedMap: { [key: string]: string } = {
    true: "Yes",
    false: "No",
  };

  const createdByMap: { [key: string]: string } = {
    self: "No",
    admin: "Yes",
  };

  return (
    <div className="w-80% px-6 py-10 relative h-auto left-20% flex flex-col justify-center font-inter bg-gray-100 ">
      <BackButton link="/dashboard/users" />
      <div className="flex items-center mb-4">
        <h1 className="text-3xl mr-4">User Details</h1>
        <span className="w-4/5 h-1 bg-gray-300"></span>
      </div>
      <div className="w-full h-72 bg-white rounded-lg shadow-xl flex">
        {loading === true ? (
          <div className="w-full pb-20 h-full flex items-center justify-center">
            <div className="loader"></div>
          </div>
        ) : (
          <>
            <div className="w-2/5 h-full flex flex-col items-center justify-center border-r border-gray-300">
              <img
                onClick={() => {
                  setImage(details?.data?.profileImage);
                  setZoomed(true);
                }}
                onError={(e) => {
                  const target = e.target as HTMLImageElement;
                  target.src =
                    " https://www.pngfind.com/pngs/m/610-6104451_image-placeholder-png-user-profile-placeholder-image-png.png";
                }}
                className="w-32 h-32 object-cover border border-black rounded-full"
                src={details?.data?.profileImage}
                alt="image could not be loaded"
              />
              <h2 className="text-xl mt-4 font-semibold">
                {details?.data?.fullName}
              </h2>
              <div className="flex items-center mt-4">
                {details?.data?.ratingCount != 0 && (
                  <>
                    {[...Array(5)].map((start, i) => {
                      const ratingValue = i + 1;
                      return (
                        <FaStar
                          color={
                            ratingValue <= details?.data?.rating
                              ? "#FDCC0D"
                              : "gray"
                          }
                          size={30}
                        />
                      );
                    })}
                    <p className="ml-4 text-gray-500">
                      {details?.data?.ratingCount} rates
                    </p>
                  </>
                )}
              </div>
            </div>
            <div className="w-3/5 h-full flex flex-col px-10 py-10">
              <div className="flex h-full justify-center flex-col">
                <div className="flex items-center mb-2">
                  <p className="mr-2 text-gray-500">Joined:</p>
                  <p className="font-semibold">
                    {details?.data?.createdAt?.slice(0, 10)}
                  </p>
                </div>
                <div className="flex items-center mb-2">
                  <p className="mr-2 text-gray-500">Phone Number:</p>
                  <p className="font-semibold">{details?.data?.phone}</p>
                </div>
                <div className="flex items-center mb-2">
                  <p className="mr-2 text-gray-500">Email:</p>
                  <p className="font-semibold">{details?.data?.email}</p>
                </div>
                <div className="flex items-center mb-2">
                  <p className="mr-2 text-gray-500">Total Orders:</p>
                  <p className="font-semibold">{details?.totalOrders}</p>
                </div>
                <div className="flex items-center mb-2">
                  <p className="mr-2 text-gray-500">Completed Orders:</p>
                  <p className="font-semibold">{details?.completedOrders}</p>
                </div>
                <div className="flex items-center mb-2">
                  <p className="mr-2 text-gray-500">Approved:</p>
                  <p className="font-semibold">
                    {approvedMap[details?.data?.isEmailVerified]}
                  </p>
                </div>
                <div className="flex items-center mb-2">
                  <p className="mr-2 text-gray-500">Created by Admin:</p>
                  <p className="font-semibold">
                    {createdByMap[details?.data?.createdBy]}
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <Table userId={id} />
      <EnlargedImage isVisible={zoomed} setVisible={setZoomed} src={image} />
    </div>
  );
};

export default UserDetails;
