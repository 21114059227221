import React, { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { login } from "./Redux/slices/auth";
import { useAppDispatch } from "./Redux/store";
import AccountSettings from "./components/AccountSettings.js";
import AddBrand from "./components/AddBrand.js";
import AddDriver from "./components/AddDriver.js";
import AddService from "./components/AddService.js";
import BrandsDetails from "./components/BrandsDetail.js";
import CreateUser from "./components/CreateUser.js";
import Dashboard from "./components/Dashboard";
import DashboardOrders from "./components/DashboardOrders.js";
import Feedback from "./components/Feedback.js";
import InDevelopment from "./components/InDevelopment.js";
import Login from "./components/Login";
import OrderDetails from "./components/OrderDetails.js";
import PrivateRoute from "./components/PrivateRoute";
import ServiceDetails from "./components/ServiceDetails.js";
import ServicesDashboard from "./components/ServicesDashboard.js";
import Sidebar from "./components/Sidebar/index";
import TestOrders from "./components/TestOrders.js";
import Transaction from "./components/Transaction.js";
import Withdrawl from "./components/Withdraw.js";
import firebase from "./firebase";
import AddAdmin from "./pages/AddAdmin";
import Admins from "./pages/Admins";
import DashboardDrivers from "./pages/DashboardDrivers";
import DashboardUsers from "./pages/DashboardUsers";
import DriverDetails from "./pages/DriverDetails";
import HelpDesk from "./pages/HelpDesk";
import Notification from "./pages/Notification";
import SendNotification from "./pages/SendNotifications";
import States from "./pages/States";
import SubmissionRequests from "./pages/SubmissionRequests";
import UserDetails from "./pages/UserDetails";
import ViewComplaint from "./pages/ViewComplaint";
import ApiManager from "./utils/ApiManager";
import { getSessionCookie } from "./utils/cookies";
import ForgotPassword from "./pages/ForgotPassword";
import PromoCodes from "./pages/PromoCodes";
// import { getSessionCookie } from "./utils/cookies";

function App() {
  // const { pathname } = useLocation();
  // const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);

  const messaging = firebase.messaging();

  const checkWhoAmI = async () => {
    const getMe = await new ApiManager().WhoAmI();
    if (getMe.success) {
      dispatch(login(getMe?.payload?.data));
    }
  };

  useEffect(() => {
    const token = getSessionCookie("token");
    if (token) {
      checkWhoAmI();
    }
  }, []);

  // React.useEffect(() => {
  //   if (!getSessionCookie("token")) {
  //     navigate("/login");
  //   }
  // }, [isLoggedIn]);

  useEffect(() => {
    messaging
      .getToken({
        vapidKey:
          "BLwe3ejWxaUV3oN63ING_wjzYkfnWBZjJ4_mUK206S4JucYf42C_Eun9ic2p3PuiZeE0X0nC4qSftRo2QcHRPvo",
      })
      .then((currentToken: any) => {
        if (currentToken) {
          // Send the token to your server and update the UI if necessary
          // ...
          new ApiManager().getNotifications(currentToken);
        } else {
          // Show permission request UI
          console.log(
            "No registration token available. Request permission to generate one."
          );
          // ...
        }
      })
      .catch((err: any) => {
        console.log("An error occurred while retrieving token. ", err);
        // ...
      });
  }, []);

  return (
    <>
      <div className="App flex">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route element={<PrivateRoute />}>
            <Route path="/dashboard" element={<Sidebar />}>
              <Route index element={<Dashboard />} />
              <Route path="users" element={<DashboardUsers />} />
              <Route path="drivers" element={<DashboardDrivers />} />
              <Route path="orders" element={<DashboardOrders />} />
              <Route path="accountSettings" element={<AccountSettings />} />
              <Route path="users/:id" element={<UserDetails />} />
              <Route path="drivers/:id" element={<DriverDetails />} />
              <Route path="brands/:id" element={<BrandsDetails />} />
              <Route path="orders/:id" element={<OrderDetails />} />
              <Route path="brands/addBrand" element={<AddBrand />} />
              <Route path="users/addUser" element={<CreateUser />} />
              <Route path="services" element={<ServicesDashboard />} />
              <Route path="withdraw" element={<Withdrawl />} />
              <Route path="transaction" element={<Transaction />} />
              <Route path="services/add" element={<AddService />} />
              <Route path="services/:id" element={<ServiceDetails />} />
              <Route path="drivers/addDriver" element={<AddDriver />} />
              <Route path="test-orders" element={<TestOrders />} />
              <Route path="feedback" element={<Feedback />} />
              <Route path="notification" element={<Notification />} />
              <Route path="send-notification" element={<SendNotification />} />
              <Route path="help-desk" element={<HelpDesk />} />
              <Route path="submit-requests" element={<SubmissionRequests />} />
              <Route path="help-desk/:id" element={<ViewComplaint />} />
              <Route path="admins" element={<Admins />} />
              <Route path="states" element={<States />} />
              <Route path="promo-codes" element={<PromoCodes />} />
              <Route path="admins/add" element={<AddAdmin />} />
              <Route path="*" element={<InDevelopment />} />
            </Route>
          </Route>
          <Route path="/*" element={<Navigate to="/login" />} />
        </Routes>
      </div>
    </>
  );
}

export default App;
