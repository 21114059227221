import React from "react";
import { useParams } from "react-router-dom";
import { useGetComplaintByIdQuery } from "../api/helpDesk/helpDesk";
import Spinner from "../components/Spinner.jsx";

const ViewComplaint = () => {
  const { id } = useParams();

  const { data, isLoading } = useGetComplaintByIdQuery(id);

  const reportingDataMap: { [index: string]: string } = {
    user: "reportingUser",
    driver: "reportingDriver",
  };

  return (
    <>
      <div className="w-80% z-10 px-6 relative left-20% flex flex-col justify-center font-inter bg-gray-100 h-screen">
        <div className="w-full bg-white py-4 px-4 rounded-lg shadow-xl flex">
          {isLoading ? (
            <Spinner />
          ) : (
            <div className="w-full p-6 flex  items-center bg-white rounded-lg">
              <div
                style={{ width: "40%" }}
                className=" flex flex-col gap-2  border-r "
              >
                <img
                  className="w-32 h-32 rounded-full"
                  onError={(e) => {
                    const target = e.target as HTMLImageElement;
                    target.src =
                      "https://www.pngfind.com/pngs/m/610-6104451_image-placeholder-png-user-profile-placeholder-image-png.png";
                  }}
                  src={data[reportingDataMap[data?.userType]]?.profileImage}
                  alt="User"
                />
                <p className="uppercase text-primary font-bold mt-2">
                  {data[reportingDataMap[data?.userType]]?.fullName}
                </p>

                <p>
                  <strong>Email: </strong>{" "}
                  {data[reportingDataMap[data?.userType]]?.email}
                </p>
              </div>

              <div
                style={{ width: "60%" }}
                className="pl-4 flex flex-col gap-2"
              >
                <p className="font-bold text-primary">Details</p>
                <p>
                  <strong>Phone Number: </strong>
                  {/* {details?.phone} */}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ViewComplaint;
