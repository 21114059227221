import React from "react";

interface PropTypes {
  children: React.ReactNode;
}

const DashboardLayout = ({ children }: PropTypes) => {
  return (
    <div className="w-full h-full">
      <div className="w-80% px-6 py-2 relative left-20% flex flex-col justify-center font-inter bg-gray-100 h-screen">
        {children}
      </div>
    </div>
  );
};

export default DashboardLayout;
