import React, { useEffect, useMemo, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { twMerge } from "tailwind-merge";
import empty from "../assets/undraw_no_data_re_kwbl.svg";
import ApiManager from "../utils/ApiManager";
import { formatPrices } from "../utils/helpers";
import ConfirmationDialog from "./ConfirmationDialog";
import DataTable, { TBody, TH, THead, TR } from "./DataTable";
import OrdersModal from "./OrdersModal";
import Pagination from "./Pagination";
import ViewFeedback from "./ViewFeedback";

export const serviceTypeMap = {
  instore_pickup_meta: "In Store",
  curbside_pickup_meta: "Curb",
  package_pickup_meta: "Individual",
};

const ServicesDashboard = () => {
  const [isViewApplocationVisible, setViewApplicationVisible] = useState(false);
  const [services, setServices] = useState([]);
  const [past, setPast] = useState("");
  const [type, setType] = useState("");
  const [totalPage, setTotalPage] = useState(1);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedFeedback, setSelectedFeedback] = useState({
    orderId: "",
    experienceRating: null,
    feedback: "",
  });
  const [selectedServiceId, setSelectedServiceId] = useState("");
  const [isCancellingService, setIsCancellingService] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({
    createdago: "",
    fromdate: "",
    todate: "",
    status: "",
    type: "",
  });

  const form = useRef();

  const getAllServices = async () => {
    setLoading(true);
    let res = await new ApiManager().getAllServices({
      limit: 10,
      ongoing: true,
      fromdate: filters.fromdate,
      todate: filters.todate,
      status: filters.status,
      page: currentPage,
      type: filters.type,
    });
    if (res?.success) {
      setServices(res?.payload?.data?.docs);
      setTotalPage(res?.payload?.data?.totalPages);
    }
    setLoading(false);
  };

  const filterServices = async (e) => {
    e.preventDefault();
    // setLoading(true);
    setFilters({
      createdago: past,
      fromdate: fromDate,
      todate: toDate,
      status: status,
      type: type,
    });
    // const res = await new ApiManager().getAllServices({
    //   type,
    //   page: 1,
    //   createdago: past,
    //   fromdate: fromDate,
    //   todate: toDate,
    //   status,
    // });
    // if (res?.success) {
    //   setServices(res?.payload?.data?.docs);
    //   setTotalPage(res?.payload?.data?.totalPages);
    // } else {
    //   alert(res?.message);
    // }
    // setLoading(false);
  };

  const handlePageClick = async (e) => {
    // setLoading(true);
    const page = e.selected + 1;
    setCurrentPage(page);
    // const res = await new ApiManager().getAllServices({
    //   type,
    //   page,
    //   createdago: past,
    //   fromdate: fromDate,
    //   todate: toDate,
    //   status,
    // });
    // if (res?.success) {
    //   setServices(res?.payload?.data?.docs);
    // }
    // setLoading(false);
  };

  const handleServiceCancel = async () => {
    setIsCancellingService(true);
    const res = await new ApiManager().cancelService(selectedServiceId);
    if (res?.status === 200) {
      let s = [...services];
      s.map((item) =>
        item?._id === selectedServiceId ? (item.status = "cancelled") : item
      );
      setServices(s);
      setSelectedServiceId("");
      toast("Service Deleted Successfully", { type: "success" });
    } else {
      setSelectedServiceId("");
      toast("Couldn't cancel service at this moment. Please try again later.", {
        type: "error",
      });
    }
    setIsCancellingService(false);
  };

  const resetFilters = () => {
    form.current.reset();
    setPast("");
    setFromDate("");
    setToDate("");
    setType("");
    setStatus("");
    setFilters({
      createdago: "",
      fromdate: "",
      status: "",
      todate: "",
      type: "",
    });
    // getAllServices();
  };

  useEffect(() => {
    getAllServices();
  }, [
    currentPage,
    filters.status,
    filters.createdago,
    filters.fromdate,
    filters.todate,
    filters.type,
  ]);

  console.log(filters, "filters");

  const statusMap = (status, className) => {
    const map = {
      arrivedAtDropoff: {
        text: "Arrived At Dropoff",
      },
      unassigned: {
        text: "Unassigned",
      },
      notProcessable: {
        text: "Not Processable",
      },
      assigned: {
        text: "Assigned",
      },
      wayToStore: {
        text: "Way To Store",
      },
      arrivedAtPickup: {
        text: "Arrived At Pickup",
      },
      confirmItems: {
        text: "Confirm Items",
      },
      proceedToDropoff: {
        text: "Proceed To Dropoff",
      },
      confirmDropoff: {
        text: "Confirm Dropoff",
      },
      delivered: {
        text: "Delivered",
      },
      ratedByUser: {
        text: "Completed",
      },
      ratedByDriver: {
        text: "Rated By Driver",
      },
      deliver: {
        text: "Deliver",
      },
      completed: {
        text: "Completed",
      },
      cancelled: {
        text: "Cancelled",
      },
    };

    const { text } = map[status];

    return <p className={className}>{text}</p>;
  };

  const sortedServices = useMemo(() => {
    const completedOrCancelled = services.filter(
      (item) => item?.status === "cancelled" || item?.status === "ratedByUser"
    );
    const others = services.filter(
      (item) => item?.status !== "cancelled" || item?.status !== "ratedByUser"
    );
    return [...others, ...completedOrCancelled];
  }, [services]);

  return (
    <>
      <div className="w-full h-full">
        {/* <Sidebar/> */}
        <Helmet>
          <title>Services Dashboard</title>
        </Helmet>

        <div className="w-80% px-6 py-2 relative left-20% flex flex-col justify-center font-inter bg-gray-100 h-screen">
          <p className="text-xl mb-4   ">Services</p>

          <div className="w-full h-20 flex justify-between items-center">
            <form
              ref={form}
              onSubmit={filterServices}
              className="w-full flex items-center justify-end mb-4"
            >
              <select
                defaultValue=""
                onChange={(e) => setStatus(e.target.value)}
                className="w-28 outline-none bg-white rounded-lg h-10 pl-4 pr-2 ml-4"
              >
                <option value="" disabled hidden>
                  Status
                </option>
                <option value="unassigned">Unassigned</option>
                <option value="assigned">Assigned</option>
                <option value="wayToStore">Way To Store</option>
                <option value="arrivedAtPickup">Arrived At Pickup</option>
                <option value="confirmItems">Confirm Items</option>
                <option value="proceedToDropoff">Proceed To Dropoff</option>
                <option value="arrivedAtDropoff">Arrived At Dropoff</option>
                <option value="confirmDropoff">Confirm Dropoff</option>
                <option value="completed">Completed</option>
                <option value="cancelled">Cancelled</option>
              </select>
              <select
                onChange={(e) => setType(e.target.value)}
                defaultValue=""
                className="w-28 outline-none bg-white rounded-lg h-10 pl-4 pr-2 ml-4"
              >
                <option value="" disabled hidden>
                  Type
                </option>
                <option value="package_pickup_meta">Individual</option>
                <option value="curbside_pickup_meta">Curb</option>
                <option value="instore_pickup_meta">In Store</option>
              </select>
              {/* <select
                defaultValue=""
                onChange={(e) => setPast(e.target.value)}
                className="w-28 outline-none bg-white rounded-lg h-10 pl-4 pr-2 ml-4"
              >
                <option value="" disabled hidden>
                  Time
                </option>
                <option value="">All</option>
                <option value="1">Today</option>
                <option value="7">Last Week</option>
                <option value="30">Last Month</option>
              </select> */}
              <div className="flex items-center ml-4">
                <label htmlFor="from_date">From: </label>
                <input
                  onChange={(e) => setFromDate(e.target.value)}
                  id="from_date"
                  className="w-32 rounded h-10 bg-white ml-2 px-2"
                  type="date"
                />
              </div>
              <div className="flex items-center ml-4">
                <label htmlFor="to_date">To: </label>
                <input
                  onChange={(e) => setToDate(e.target.value)}
                  id="to_date"
                  className="w-32 rounded h-10 bg-white ml-2 px-2"
                  type="date"
                />
              </div>
              <button
                type="button"
                onClick={resetFilters}
                className="w-24 ml-4 h-10 bg-transparent border rounded-lg border-primary text-primary"
              >
                Clear
              </button>

              <button
                type="submit"
                className="w-28 ml-4 h-10 bg-primary rounded-lg  text-white"
              >
                Apply
              </button>
              <button
                type="button"
                onClick={() => window.location.reload(true)}
                className="w-32 bg-transparent border border-primary text-primary h-10 ml-4 rounded"
              >
                Refresh
              </button>
            </form>
          </div>
          <div className="h-4/5 overflow-auto flex flex-col justify-between w-full bg-white rounded-lg">
            {loading === false && services?.length === 0 ? (
              <div className="w-full h-full flex flex-col items-center justify-center">
                <img className="w-1/5" src={empty} />
                <p className="mt-10 text-2xl text-gray-500 font-inter">
                  No Services Found
                </p>
              </div>
            ) : (
              <DataTable className="w-full h-4/5 overflow-y-auto overflow-x-auto">
                {loading && loading === true ? (
                  <div className="w-full h-full flex items-center justify-center">
                    <div className="loader"></div>
                  </div>
                ) : (
                  <>
                    <THead>
                      <TR>
                        <TH>Driver</TH>
                        <TH>User</TH>
                        <TH className="px-0">Status</TH>
                        <TH>User Total</TH>
                        <TH>Driver Total</TH>
                        <TH>Type</TH>
                        <TH>Actions</TH>
                      </TR>
                    </THead>
                    <TBody>
                      {services?.map((order) => (
                        <TR key={order?._id}>
                          <TH>
                            {order?.driver?.length === 0 ? (
                              <p className="text-md text-black font-normal break-all  ">
                                {order?.driver?.length === 0
                                  ? "N/A"
                                  : order?.driver[0]?.fullName}
                              </p>
                            ) : (
                              <Link
                                to={`/dashboard/drivers/${order?.driver[0]?._id}`}
                              >
                                <p className="text-md font-normal text-black break-all  ">
                                  {order?.driver?.length === 0
                                    ? "N/A"
                                    : order?.driver[0]?.fullName}
                                </p>
                              </Link>
                            )}
                          </TH>
                          <TH>
                            {order?.user?.length === 0 ? (
                              <p className="text-md break-all font-normal text-black ">
                                N/A
                              </p>
                            ) : (
                              <Link
                                to={`/dashboard/users/${order.user[0]?._id}`}
                              >
                                <p className="text-md break-all font-normal text-black ">
                                  {order?.user[0]?.fullName}
                                </p>
                              </Link>
                            )}
                          </TH>
                          <TH
                            className={twMerge(
                              "flex text-md font-normal text-orange-600 break-all p-0 h-full mt-7",
                              order?.status === "cancelled" && "text-red-600",
                              (order?.status === "ratedByUser" ||
                                order?.status === "completed") &&
                                "text-green-600"
                            )}
                          >
                            {statusMap(order.status)}
                          </TH>
                          <TH className="font-normal text-black">
                            {order?.userTotal
                              ? formatPrices(order?.userTotal)
                              : "N/A"}
                          </TH>
                          <TH className="font-normal text-black">
                            {formatPrices(order?.driverTotal)}
                          </TH>
                          <TH className="break-all font-normal text-black">
                            {serviceTypeMap[order?.serviceMetaType]}
                          </TH>
                          <TH className="">
                            <Link to={`/dashboard/services/${order?._id}`}>
                              <button className="text-primary h-10  cursor-pointer ">
                                Details
                              </button>
                            </Link>
                            {order?.ratingByUser && (
                              <button
                                onClick={() =>
                                  setSelectedFeedback({
                                    orderId: order?._id,
                                    experienceRating:
                                      order?.ratingByUser?.rating,
                                    feedback: order?.ratingByUser?.feedback,
                                  })
                                }
                                className="text-primary px-4 h-10 ml-4"
                              >
                                View Feedback
                              </button>
                            )}
                            {order?.status ===
                            "ratedByUser" ? null : order?.status ===
                              "cancelled" ? null : (
                              <button
                                onClick={() => setSelectedServiceId(order?._id)}
                                className="text-red-600 ml-4"
                              >
                                Cancel
                              </button>
                            )}
                          </TH>
                        </TR>
                      ))}
                    </TBody>
                  </>
                )}
              </DataTable>
            )}
          </div>
          <div className="mt-6">
            <Pagination
              page={totalPage}
              handlePageClick={(e) => handlePageClick(e)}
            />
          </div>
        </div>
        <OrdersModal
          isVisible={isViewApplocationVisible}
          setVisible={setViewApplicationVisible}
        />
      </div>
      <ViewFeedback
        feedback={selectedFeedback}
        isVisible={selectedFeedback.orderId !== ""}
        screen="driverDetails"
        setVisible={() =>
          setSelectedFeedback({
            experienceRating: null,
            feedback: "",
            orderId: "",
          })
        }
      />
      <ConfirmationDialog
        isSubmitButtonDisabled={isCancellingService}
        isSubmitting={isCancellingService}
        handleClick={handleServiceCancel}
        isVisible={selectedServiceId !== ""}
        onHide={() => setSelectedServiceId("")}
        text="Are you sure you want to cancel this service ?"
      />
    </>
  );
};

export default ServicesDashboard;
