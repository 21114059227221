import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { fetchBaseQueryHelper } from "../../utils/helpers";

const driverApi = createApi({
  reducerPath: "driverApi",
  baseQuery: fetchBaseQuery(fetchBaseQueryHelper),
  tagTypes: ["DriverDetails"],
  endpoints: (builder) => ({
    getDriverDetails: builder.query({
      query: (id) => ({
        url: `/admin/driver/${id}`,
      }),
      providesTags: ["DriverDetails"],
    }),
    modifyDriver: builder.mutation({
      query: (body) => {
        const { id, ...rest } = body;
        return {
          url: `/admin/driver/${id}/modify`,
          body: rest,
          method: "PATCH",
        };
      },
      invalidatesTags: ["DriverDetails"],
    }),
    getResubmitedDocuments: builder.query({
      query: (id) => ({
        url: `/admin/resubmit/${id}`,
      }),
    }),
    approveOrRejectResubmittedDocuments: builder.mutation({
      query: ({ id, isApproved }) => ({
        url: `/admin/resubmit/${id}/modify`,
        body: { isApproved },
        method: "PATCH",
      }),
      invalidatesTags: ["DriverDetails"],
    }),
  }),
});

export const {
  useGetDriverDetailsQuery,
  useModifyDriverMutation,
  useGetResubmitedDocumentsQuery,
  useApproveOrRejectResubmittedDocumentsMutation,
} = driverApi;

export default driverApi;
