import { Dialog, Transition } from "@headlessui/react";
import { useFormik } from "formik";
import React, { Fragment, useCallback } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { PromoCodeType } from "../pages/PromoCodes";
import ApiManager from "../utils/ApiManager";
import { formatDate } from "../utils/helpers";
import Button from "./Button";
import Checkbox from "./Checkbox";
import Input from "./Input";
import Select from "./Select";
import Textarea from "./Textarea";
import Toggle from "./Toggle";

interface PropTypes {
  isVisible: boolean;
  onHide: () => void;
  onSuccess: () => void;
  promoCode: PromoCodeType | null;
}

export const getTodayDate = () => {
  const today = new Date();
  return today.toISOString().split("T")[0];
};

export const serviceTypes = [
  { label: "Package Pickup", value: "package_pickup_meta" },
  { label: "Curbside Pickup", value: "curbside_pickup_meta" },
  { label: "Instore Pickup", value: "instore_pickup_meta" },
];

const discountOptions = [
  {
    label: "Flat Discount",
    value: "flat",
  },
  {
    label: "Percentage Discount",
    value: "percentage",
  },
];

type FormValues = {
  promoCode: string;
  serviceType: string[]; // Explicitly define serviceType as an array of strings
  discountType: string;
  discountValue: string;
  description: string;
  startDate: string;
  expiryDate: string | null;
  maxUsers: string;
  maxBudget: string;
  isNotifyUser: string;
  isPromoCodeActive: boolean;
};

const EditPromoCodeModal = ({
  isVisible,
  onHide,
  onSuccess,
  promoCode,
}: PropTypes) => {
  const formik = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      promoCode: promoCode?.promoCode ?? "",
      serviceType: promoCode?.serviceType ?? [],
      discountType: promoCode?.discountType ?? "",
      discountValue: promoCode?.discountValue.toString() ?? "",
      description: promoCode?.description ?? "",
      startDate: promoCode?.startDate ? formatDate(promoCode.startDate) : "",
      expiryDate: promoCode?.expiryDate
        ? formatDate(promoCode.expiryDate)
        : null,
      maxUsers: promoCode?.maxUsers?.toString() ?? "",
      maxBudget: promoCode?.maxBudget?.toString() ?? "",
      isNotifyUser:
        promoCode?.isNotifyUser === true
          ? "true"
          : promoCode?.isNotifyUser === false
          ? "false"
          : "",
      isPromoCodeActive: true,
    },
    validationSchema: Yup.object().shape({
      promoCode: Yup.string()
        .trim()
        .matches(/^\S*$/, "Promo code cannot contain spaces")
        .required("This field is required"),
      serviceType: Yup.array()
        .min(1, "At least one service is required")
        .required("This field is required"),
      discountType: Yup.string().required("This field is required"),
      discountValue: Yup.number().positive().required("This field is required"),
      description: Yup.string().trim().required("This field is required"),
      startDate: Yup.date()
        .min(getTodayDate(), "Start Date must be today or future date")
        .required("This field is required"),
      maxUsers: Yup.number().positive(),
      maxBudget: Yup.number().positive(),
      expiryDate: Yup.date()
        .min(Yup.ref("startDate"), "Expiry date must be after the start date")
        .test(
          "is-greater",
          "Expiry date must be after the start date",
          function (value) {
            const { startDate } = this.parent;
            if (!value) return true; // Pass if endDate is not provided (optional)
            return new Date(value) > new Date(startDate);
          }
        )
        .nullable(),
      isNotifyUser: Yup.string().required("This field is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const {
        serviceType,
        maxBudget,
        isNotifyUser,
        maxUsers,
        discountValue,
        ...rest
      } = values;
      const res: any = await new ApiManager().editPromo({
        ...rest,
        serviceType,
        maxUsers: parseInt(maxUsers),
        maxBudget: parseInt(maxBudget),
        discountValue: parseInt(discountValue),
        isNotifyUser: isNotifyUser === "true" ? true : false,
        id: promoCode?._id!,
      });
      if (res.success) {
        toast("Promo Code Edited Successfully", { type: "success" });
        resetForm();
        onSuccess();
        onHide();
      } else {
        toast(res.error?.response?.data?.error, {
          type: "error",
        });
      }
    },
  });

  const handleServiceTypeChange = useCallback(
    (service: { label: string; value: string }, checked: boolean) => {
      const currentServiceTypes = [...formik.values.serviceType];
      if (checked) {
        formik.setFieldValue("serviceType", [
          ...currentServiceTypes,
          service.value,
        ]);
      } else {
        formik.setFieldValue(
          "serviceType",
          currentServiceTypes.filter((item) => item !== service.value)
        );
      }
    },
    [formik.values.serviceType, formik.setFieldValue]
  );

  console.log(formik.values.serviceType, "Service typ");

  return (
    <Transition.Root show={isVisible} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={() => {
          formik.resetForm();
          onHide();
        }}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block p-4 align-bottom bg-white rounded-2xl text-left overflow-hidden shadow-xl transform transition-all lg:max-w-3xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <h1 className="text-lg font-bold mb-4 text-center">
                Edit Promo Code
              </h1>
              <form onSubmit={formik.handleSubmit}>
                <div className="grid grid-cols-5 gap-x-4 gap-y-2">
                  <div className="grid col-span-4 grid-cols-5 gap-x-4 gap-y-2">
                    <Input
                      placeholder="TEST123"
                      isInvalid={Boolean(
                        formik.touched.promoCode && formik.errors.promoCode
                      )}
                      className="input-primary"
                      containerClassName="col-span-4"
                      label="Promo Code"
                      name="promoCode"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.errors.promoCode}
                      value={formik.values.promoCode}
                    />

                    <Input
                      type="date"
                      isInvalid={Boolean(
                        formik.touched.startDate && formik.errors.startDate
                      )}
                      containerClassName="col-span-1"
                      label="Valid From"
                      className="input-primary"
                      name="startDate"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.errors.startDate}
                      value={formik.values.startDate}
                    />
                    <Textarea
                      containerClassName="col-span-5"
                      placeholder="Test Discount"
                      className="textarea-primary"
                      isInvalid={Boolean(
                        formik.touched.description && formik.errors.description
                      )}
                      label="Discount Description"
                      name="description"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.errors.description}
                      value={formik.values.description}
                    />
                    <Select
                      placeholder="Select a Discount Type"
                      isInvalid={Boolean(
                        formik.touched.discountType &&
                          formik.errors.discountType
                      )}
                      className="select-primary"
                      containerClassName="col-span-2"
                      label="Discount Type"
                      name="discountType"
                      value={formik.values.discountType}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.errors.discountType}
                      options={discountOptions}
                    />
                    <Input
                      placeholder="Enter Value"
                      isInvalid={Boolean(
                        formik.touched.discountValue &&
                          formik.errors.discountValue
                      )}
                      label={`Of ${
                        formik.values.discountType === "flat"
                          ? "(in $)"
                          : formik.values.discountType === "percentage"
                          ? "(in %)"
                          : ""
                      }`}
                      className="input-primary"
                      name="discountValue"
                      value={formik.values.discountValue}
                      type="number"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.errors.discountValue}
                    />
                    <Input
                      placeholder="Enter Value"
                      type="number"
                      isInvalid={Boolean(
                        formik.touched.maxUsers && formik.errors.maxUsers
                      )}
                      className="input-primary"
                      label="Max Users"
                      name="maxUsers"
                      onChange={formik.handleChange}
                      value={formik.values.maxUsers}
                      onBlur={formik.handleBlur}
                      error={formik.errors.maxUsers}
                    />
                    <Input
                      placeholder="Enter Value"
                      type="number"
                      className="input-primary"
                      isInvalid={Boolean(
                        formik.touched.maxBudget && formik.errors.maxBudget
                      )}
                      label="Max Budget"
                      name="maxBudget"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.errors.maxBudget}
                      value={formik.values.maxBudget}
                    />
                  </div>
                  <div className="grid col-span-1 grid-cols-1 gap-2">
                    <Input
                      type="date"
                      isInvalid={Boolean(
                        formik.touched.expiryDate && formik.errors.expiryDate
                      )}
                      label="Valid Till (Optional)"
                      name="expiryDate"
                      className="input-primary"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.errors.expiryDate}
                      value={
                        formik.values.expiryDate ? formik.values.expiryDate : ""
                      }
                    />
                    <div>
                      <div className="label">
                        <span className="label-text">Service Type</span>
                      </div>
                      <Checkbox
                        onChange={(e) => {
                          if (e.target.checked) {
                            formik.setFieldValue(
                              "serviceType",
                              serviceTypes.map((item) => item.value)
                            );
                          } else {
                            formik.setFieldValue("serviceType", []);
                          }
                        }}
                        label="All"
                        checked={serviceTypes.every((item) =>
                          formik.values.serviceType.includes(item.value)
                        )}
                      />
                      {serviceTypes.map((service) => (
                        <Checkbox
                          key={service.value}
                          onChange={(e) =>
                            handleServiceTypeChange(service, e.target.checked)
                          }
                          label={service.label}
                          checked={
                            formik.values.serviceType.includes(service.value) ||
                            formik.values.serviceType.includes("all")
                          }
                        />
                      ))}
                      {formik.touched.serviceType &&
                      formik.errors.serviceType ? (
                        <div className="label">
                          <span className="label-text-alt text-red-600">
                            {formik.errors.serviceType}
                          </span>
                        </div>
                      ) : null}
                    </div>
                    <Toggle
                      checked={formik.values.isNotifyUser === "true"}
                      name="isNotifyUser"
                      onChange={(e) =>
                        formik.setFieldValue(
                          "isNotifyUser",
                          e.target.checked.toString()
                        )
                      }
                      isInvalid={Boolean(
                        formik.touched.isNotifyUser &&
                          formik.errors.isNotifyUser
                      )}
                      error={formik.errors.isNotifyUser}
                      label="Notify User"
                    />
                  </div>
                </div>
                <div className="flex mt-4 gap-2 items-center justify-end">
                  <Button
                    onClick={() => {
                      formik.resetForm();
                      onHide();
                    }}
                    type="button"
                    variant="outline"
                  >
                    Close
                  </Button>
                  <Button isLoading={formik.isSubmitting} type="submit">
                    Edit
                  </Button>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default EditPromoCodeModal;
