import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import driverApi from "../api/drivers/drivers";
import helpDeskApi from "../api/helpDesk/helpDesk";
import notificationsApi from "../api/notifications/notifications";
import servicesApi from "../api/services/services";
import submissionRequestsApi from "../api/submissionRequests/submissionRequest";
import usersApi from "../api/users/users";
import auth from "./slices/auth";
import ui from "./slices/ui";
import adminApi from "../api/admins";

const store = configureStore({
  reducer: {
    auth,
    ui,
    [usersApi.reducerPath]: usersApi.reducer,
    [servicesApi.reducerPath]: servicesApi.reducer,
    [driverApi.reducerPath]: driverApi.reducer,
    [helpDeskApi.reducerPath]: helpDeskApi.reducer,
    [submissionRequestsApi.reducerPath]: submissionRequestsApi.reducer,
    [notificationsApi.reducerPath]: notificationsApi.reducer,
    [adminApi.reducerPath]: adminApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(usersApi.middleware)
      .concat(servicesApi.middleware)
      .concat(driverApi.middleware)
      .concat(helpDeskApi.middleware)
      .concat(submissionRequestsApi.middleware)
      .concat(notificationsApi.middleware)
      .concat(adminApi.middleware),
});

export default store;

type RootState = ReturnType<typeof store.getState>;
type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
