import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import empty from "../assets/undraw_no_data_re_kwbl.svg";
import AddStateModal from "../components/AddStateModal";
import DataTable, { TBody, TH, THead, TR } from "../components/DataTable";
import Pagination from "../components/Pagination.js";
import Spinner from "../components/Spinner.jsx";
import ApiManager from "../utils/ApiManager";
import ConfirmationDialog from "../components/ConfirmationDialog";
import { toast } from "react-toastify";
import Button from "../components/Button";

type StateType = {
  _id: string;
  name: string;
  symbol: string;
};

const States = () => {
  const [states, setStates] = useState<StateType[]>([]);
  const [isAddingState, setIsAddingState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [selectedStateId, setSelectedStateId] = useState("");
  const [isDeletingState, setIsDeletingState] = useState(false);

  const searchRef = useRef<HTMLInputElement>(null);

  const handleGetAllStates = async () => {
    setIsLoading(true);
    const res = await new ApiManager().getLaunchedStates({
      page: currentPage,
      search,
    });
    if (res.success) {
      setStates(res.data?.docs);
      setTotalPages(res.data?.totalPages);
    }
    setIsLoading(false);
  };

  const handleStateDelete = async () => {
    setIsDeletingState(true);
    const res = await new ApiManager().deleteState(selectedStateId);
    if (res.success) {
      toast("State Deleted Successfully", { type: "success" });
      setSelectedStateId("");
      handleGetAllStates();
    } else {
      toast("Something went wrong, please try again later", { type: "error" });
    }
    setIsDeletingState(false);
  };

  useEffect(() => {
    handleGetAllStates();
  }, [currentPage, search]);

  return (
    <>
      <Helmet>
        <title>States</title>
      </Helmet>
      <div className="w-full h-full">
        <div className="w-80% px-6 py-2 relative left-20% flex flex-col justify-center font-inter bg-gray-100 h-screen">
          <div className="flex items-center mb-4 justify-end">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                setCurrentPage(1);
                if (searchRef.current) setSearch(searchRef.current.value);
              }}
            >
              <input
                ref={searchRef}
                className="w-52 ml-4 px-4 h-10 outline-none rounded "
                placeholder="search"
              />
              <button
                type="submit"
                className="w-32 h-10 ml-4 rounded bg-primary text-white"
              >
                Search
              </button>
            </form>
            <button
              onClick={() => setIsAddingState(true)}
              className="ml-4 border border-primary text-primary  w-32 py-2 rounded"
            >
              Add State
            </button>
          </div>
          <div className="h-4/5 overflow-auto flex flex-col justify-between w-full bg-white rounded-lg">
            {isLoading ? (
              <Spinner />
            ) : states?.length === 0 ? (
              <div className="w-full h-full flex flex-col items-center justify-center">
                <img className="w-1/5" src={empty} />
                <p className="mt-10 text-2xl text-gray-500 font-inter">
                  No States Found
                </p>
              </div>
            ) : (
              <DataTable>
                <THead>
                  <TR>
                    <TH>Name</TH>
                    <TH>Symbol</TH>
                    <TH>Actions</TH>
                  </TR>
                </THead>
                <TBody>
                  {states?.map((state, index: number) => (
                    <TR key={index}>
                      <TH>{state?.name || "N/A"}</TH>
                      <TH>{state?.symbol || "N/A"}</TH>
                      <TH>
                        <Button
                          onClick={() => setSelectedStateId(state._id)}
                          className="btn btn-ghost text-red-600"
                        >
                          Delete
                        </Button>
                      </TH>
                    </TR>
                  ))}
                </TBody>
              </DataTable>
            )}
          </div>
          <div className="mt-6">
            <Pagination
              page={totalPages}
              handlePageClick={(e: { selected: number }) =>
                setCurrentPage(e.selected + 1)
              }
            />
          </div>
        </div>
      </div>
      <AddStateModal
        isVisible={isAddingState}
        onHide={() => setIsAddingState(false)}
        onSuccess={handleGetAllStates}
      />
      <ConfirmationDialog
        handleClick={handleStateDelete}
        isSubmitButtonDisabled={isDeletingState}
        isSubmitting={isDeletingState}
        text="Are you sure you want to delete this state ?"
        isVisible={selectedStateId !== ""}
        onHide={() => setSelectedStateId("")}
      />
    </>
  );
};

export default States;
