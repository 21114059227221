import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { fetchBaseQueryHelper } from "../../utils/helpers";

const notificationsApi = createApi({
  reducerPath: "notificationsApi",
  baseQuery: fetchBaseQuery(fetchBaseQueryHelper),
  tagTypes: ["Notifications"],
  endpoints: (builder) => ({
    getAllNotifications: builder.query({
      query: (params) => ({
        url: "/notifications/admin/all",
        params: { ...params },
      }),
      providesTags: ["Notifications"],
    }),
    deleteNotification: builder.mutation({
      query: (id) => ({
        url: `/notifications/admin/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Notifications"],
    }),
  }),
});

export const { useGetAllNotificationsQuery, useDeleteNotificationMutation } =
  notificationsApi;
export default notificationsApi;
